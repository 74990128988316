import React, {useState} from 'react';
import {Anchor, Image, Layout, Typography} from 'antd';
import {
    AppstoreOutlined,
    DesktopOutlined, GiftOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import Sider from "antd/es/layout/Sider";
import {Content, Header} from "antd/es/layout/layout";
import Logo from "../layout/Logo";
import styles from "./ApiDocsPage.module.css"
import {EMAIL} from "../../config/contact";
import classNames from "classnames";
import {REDIRECT_BODY, SHARABLE_LINK_BODY} from "../../config/apiDocs";
import redirectDiagram from "../../assets/redirectDiagram.png"
import {useNavigate} from "react-router-dom";

const {Title, Paragraph, Text} = Typography;

const getItem = (title, key, href, children, type) => ({
    key,
    href,
    children,
    title,
    type,
});

const ApiDocsPage = () => {
    const navigate = useNavigate();
    const [breakpoint, setBreakpoint] = useState(false);

    const items = [
        getItem(<span
            style={{color: "white"}}><PieChartOutlined/> {!breakpoint && 'Get started'}</span>, '1', "#get-started"),
        getItem(<span
            style={{color: "white"}}><DesktopOutlined/> {!breakpoint && 'Prerequisites'}</span>, '2', "#prerequisites"),
        getItem(<span
            style={{color: "white"}}><AppstoreOutlined/> {!breakpoint && 'Redirect with preset items'}</span>, '3', '#redirect', !breakpoint && [
            getItem(<span style={{color: "white"}}>Request body</span>, '5', "#redirect-request-body")
        ]),
        getItem(<span
            style={{color: "white"}}><AppstoreOutlined/> {!breakpoint && 'Add items and receive sharable link'}</span>, '4', "#sharable-link", !breakpoint && [
            getItem(<span style={{color: "white"}}>Request body</span>, '#sharable-link-request-body')
        ]),
    ];

    return (
        <Layout hasSider className={styles.mainContainer}>
            <Sider
                breakpoint="sm"
                onBreakpoint={(broken) => {
                    setBreakpoint(broken);
                }}
                collapsedWidth="50"
                className={styles.siderContainer}
                width={300}
            >
                <div style={{
                    padding: breakpoint ? 4 : 24,
                    fontSize: breakpoint ? 28 : "initial",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer"
                }}
                onClick={() => navigate("/")}
                >
                    {breakpoint ? <GiftOutlined style={{color: "#bc83fc"}}/> : <Logo/>}
                </div>
                <Anchor items={items}/>
            </Sider>
            <Layout style={{marginLeft: breakpoint ? 50 : 300}}>
                <Header style={{padding: 0, background: "#001529", textAlign: "center", fontSize: 28, color: "white"}}>GiftPick
                    API Documentation</Header>
                <Content>
                    <section className={styles.textContainer}>
                        <Title level={3} id={"get-started"}>1. Get started - main information</Title>
                        <p>
                            GiftPick is a service of creating gift proposition for your friends, lovers, colleagues,
                            co-workers etc., that's why you should start using it.
                            <br/>
                            This documentation is about integrating your service into your API. It should be done
                            backend side because of passed API key.
                        </p>

                        <Title level={3} id={"prerequisites"} className={classNames(styles.mt48)}>2. Prerequisites - what
                            do you need</Title>
                        <p>
                            Firstly, you will need API key to make a call to our services.
                            <br/>
                            API key for sandbox and production can be retrieved by sending e-mail to: {'   '}
                            <Typography.Text strong copyable className={styles.whiteText}>{EMAIL}</Typography.Text>
                        </p>
                        <p className={styles.mt48}>
                            Here is template what your e-mail should have:
                        </p>
                        <pre>
                            First Name and Last name:{'\n'}
                            Contact email:{'\n'}
                            Website url:{'\n'}
                            Company name:{'\n'}
                            Company Logo image url:{'\n'}
                            How many requests per minute you predict on sandbox environment?:
                        </pre>
                        <br/>Company name and company logo is not required but it will show at the top of the page. It
                        will look more professional and more user friendly. User will know from which page he was
                        redirected.

                        <p className={styles.mt48}>
                            We will try to respond as soon as possible. You will receive test API-key with limited
                            requests rate. What's more company ID will be passed to you. You will have to put it into
                            the request body if you want you company logo and title to be shown during gifts creation.
                        </p>
                        <Paragraph bold>Server URL will be passed in message</Paragraph>

                        API Key needs to be passed in header 'X-API-Key'. Example:
                        <pre>
                            X-API-Key: 123123
                        </pre>
                        <p className={styles.mt48}>
                            <b>Sandbox usage plan details :</b>
                        </p>
                        <p>
                            <b>Rate :</b> 5 requests per second
                        </p>
                        <p>
                            <b>Quota :</b> 100 requests per day
                        </p>
                        <p>
                            <b>Burst :</b> 1 requests
                        </p>

                        <Title level={3} id={"redirect"} className={styles.mt48}>3. Redirect user</Title>
                        <p>
                            You can redirect user from your website to GiftPick when he can fill rest of the form.
                        </p>
                        <p>
                            He will be able to choose welcome page title and description. Then contact details which will be used to inform him. The items from your website will appear in second step. He can make remove some of them if he wants but he can't add his own.
                        </p>
                        <Image style={{margin: "48px 0"}} alt={'Redirect to our website logic diagram'} src={redirectDiagram}/>
                        <Paragraph code style={{fontSize: '2em'}}>/gift</Paragraph>
                        <pre id={"redirect-request-body"}>
                            {REDIRECT_BODY}
                        </pre>
                        <p>URL variable in items is optional if you don't want to pass details (about the price for example) to the end receiver.</p>
                        <p>Company ID is variable received from us. By this we fetch company name and company logo</p>

                        <Title level={3} id={"sharable-link"} className={styles.mt48}>4. Create sharable link</Title>
                        <p>
                            Of course, we can skip creation flow and instantly receive sharable link. This link can be send to receiver to choose his gift.
                        </p>
                        <Paragraph code style={{fontSize: '2em'}}>/gift/items</Paragraph>
                        <pre id={"sharable-link-request-body"}>
                            {SHARABLE_LINK_BODY}
                        </pre>
                        <p>
                            Optional fields: [companyId, items.url, phone, description]
                        </p>
                        <p>
                            backgroundType can be set to NONE or values in the example body
                        </p>
                        <p>URL variable in items is optional if you don't want to pass details (about the price for example) to the end receiver.</p>
                        <p>Company ID is variable received from us. By this we fetch company name and company logo</p>

                    </section>
                </Content>
            </Layout>
        </Layout>
    );
};

export default ApiDocsPage;