import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import HttpService from "../../../service/HttpService";
import Lottie from "lottie-react";
import animationLoading from "../../../assets/AnimationLoading.json";
import {Empty, Flex, Image, Spin, Typography, message} from "antd";
import StepsContainer from "../StepsContainer";
import {motion} from "framer-motion"
import {useTranslation} from "react-i18next";

const CompanyStepsPage = () => {
    const [gift, setGift] = useState(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const {t} = useTranslation('steps');

    useEffect(() => {
        HttpService.getGift(params.giftId)
            .then(({data}) => setGift({
                ...data,
                items: data.items.map(item => ({...item, imgIndex: 0, images: [item.image]}))
            }))
            .catch(error => {
                console.error(error);
                message.error(t('notification.company'))
            })
            .finally(() => setLoading(false));
        console.log(gift)
    }, [params])

    return (
        <Spin size={'large'} spinning={loading} indicator={
            <Lottie animationData={animationLoading} loop={true} style={{transform: 'scale(5)'}}/>}>
            {gift ?
                <Flex vertical justify={"center"} align={"center"} style={{width: '100%'}}>
                    <motion.div
                        initial={{scaleY: 0}}
                        style={{maxWidth: 400, padding: 24}}
                        animate={{
                            scaleY: 1,
                            transition: {delay: 0.5, type: "tween"}
                        }}
                        exit={{
                            opacity: 0,
                            transition: {delay: 0.5}
                        }}

                        layout
                        transition={{duration: 0.5}}>
                        <Image src={gift.companyImg} alt={`${gift.companyName} Company logo`} preview={false}/>
                        <Typography.Title level={3}
                                          style={{margin: 0, textAlign: "center"}}>{gift.companyName}</Typography.Title>
                    </motion.div>
                    <StepsContainer companyItems={gift.items} companyIntegrationEnabled
                                    company={{name: gift.companyName, image: gift.companyImg, id: gift.companyId}}/>
                </Flex>
                : <Empty/>
            }
        </Spin>
    );
};

export default CompanyStepsPage;