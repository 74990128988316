import React, {useEffect, useState} from 'react';
import {Flex, Layout} from "antd";
import {Content, Footer} from "antd/es/layout/layout";
import {Link, Outlet} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./Navbar";
import {useTranslation} from "react-i18next";

const LayoutWrapper = () => {
    const { t } = useTranslation('layout');

    const getWindowSize = () => {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(getWindowSize());
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <Layout style={{background: "white"}}>
            <Navbar/>

            <Content style={{
                minHeight: '100dvh',
                width: '100%',
                zIndex: 1,
                maxWidth: '100vw',
                overflowX: windowSize.innerWidth > 940 ? 'initial' : 'hidden'

            }}>
                <ScrollToTop/>
                <Outlet/>
            </Content>
            <Footer style={{backgroundColor: "#001529", color: "white", zIndex: 1, fontSize: 18, textAlign: "center"}}>
                <Flex vertical align={"center"} justify={"space-between"} wrap={"wrap"} style={{width: "100%", paddingBottom: 24}}>
                    <h4>GiftPick ©{new Date().getFullYear()} Created by Adrian Sadurski</h4>
                    <Flex align={"center"} justify={"space-evenly"} wrap={"wrap"} style={{width: "100%"}}>
                        <Link to={"/contact"}>{t('contact')}</Link>
                        <Link to={"/privacy-policy"}>{t('privacyAndPolicy')}</Link>
                        <Link to={"/rules"}>{t('rules')}</Link>
                    </Flex>
                </Flex>
            </Footer>
        </Layout>
    );
};

export default LayoutWrapper;