import React from 'react';
import {AnimatePresence, motion} from "framer-motion"
import {Divider, Form} from "antd";
import {Radio} from 'antd';
import {BACKGROUNDS} from "../../config/constants";
import Background from "../background/Background";
import {useTranslation} from "react-i18next";

const FinishStepContainer = ({stepForm}) => {
    const configName = Form.useWatch('configName', stepForm);
    const {t} = useTranslation('steps');

    return (
        <AnimatePresence>
            <motion.div
                initial={{scaleX: 0}}

                animate={{
                    scaleX: 1,
                    transition: {delay: 0.5, type: "tween"}
                }}
                exit={{
                    opacity: 0,
                    transition: {delay: 0.5}
                }}

                layout
                transition={{duration: 0.5}}>
                {configName && <Background configName={configName}/>}
                <Form form={stepForm} layout="vertical" autoComplete="off">
                    <Divider>Welcome page</Divider>
                    <Form.Item
                        name="configName"
                        label={t('finish.title')}
                        help={t('finish.help')}
                    >
                        <Radio.Group defaultValue={"NONE"} size="large">
                            <Radio.Button value="NONE">{t(`finish.background.NONE`)}</Radio.Button>)}
                            {Object.entries(BACKGROUNDS).map(([key, value]) =>
                                <Radio.Button key={key} value={key}>{t(`finish.background.${key}`)}</Radio.Button>)}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </motion.div>
        </AnimatePresence>
    );
};

export default FinishStepContainer;