import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Flex,
    Form, Image,
    message,
    Result,
    Spin, Typography,
    Watermark
} from "antd";
import Background from "../background/Background";
import {useParams} from "react-router";
import HttpService from "../../service/HttpService";
import {useNavigate, useSearchParams} from "react-router-dom";
import ChooseItemsContainer from "./ChooseItemsContainer";
import WelcomeModal from "./WelcomeModal";
import ShippingDetails from "./ShippingDetails";
import {useTranslation} from "react-i18next";

const ChoosePage = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorPage, setErrorPage] = useState(false)
    const [gift, setGift] = useState(null)
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [options, setOptions] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const params = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {t} = useTranslation('choose')

    useEffect(() => {
        HttpService.getGift(params.giftId)
            .then(({data}) => setGift(data))
            .catch(error => {
                console.error(error);
                setErrorPage(true);
            })
            .finally(() => setLoading(false));
    }, [params])

    const toggleModal = () => {
        setIsModalOpen((p) => !p);
    };

    const handleCardClick = (index) => {
        if (expandedIndex !== index) {
            form.setFieldValue("expandedIndex", index);
            setExpandedIndex(index === expandedIndex ? -1 : index)
            setModalOpen(gift?.items[index]?.options?.length > 0)
            setOptions(null);
        }
    }

    const onSubmit = (values) => {
        setLoading(true);

        HttpService.chooseGift({giftId: gift.id, choseIndex: expandedIndex, shipping: {...values}, options})
            .then(() => {
                navigate("/choose-success");
            })
            .catch(error => {
                console.error(JSON.stringify(error));
                message.error(t('notification.error'));
            })
            .finally(() => setLoading(false));
    }

    const getContent = () => <Flex vertical align={"center"} justify={"center"}>
        {gift.companyImg && <Image style={{maxWidth: 400, padding: 24}}
                                   src={gift.companyImg} alt={`${gift.companyName} Company logo`} preview={false}/>}
        {gift.companyName && <Typography.Title level={3} style={{margin: 0}}>{gift.companyName}</Typography.Title>}
        <Divider style={{borderBlockStartColor: "#bc83fc"}}><b
            style={{color: "gray", fontSize: 24}}>{t('divider.gift')} :</b></Divider>
        <WelcomeModal description={gift.description} title={gift.title}
                      isModalOpen={isModalOpen}
                      company={gift.companyImg && {name: gift.companyName, image: gift.companyImg}}
                      toggleModal={toggleModal}/>
        {gift.backgroundType && gift.backgroundType !== 'NONE' &&
            <Background configName={gift.backgroundType}/>}
        {gift.items &&
            <ChooseItemsContainer expandedIndex={expandedIndex} items={gift.items}
                                  hasError={!options && expandedIndex !== null && gift.items[expandedIndex]?.options?.length > 0}
                                  openModal={() => setModalOpen(true)}
                                  handleCardClick={handleCardClick}/>}
        <Divider style={{borderBlockStartColor: "#bc83fc"}}><b
            style={{color: "gray", fontSize: 24}}>{t('divider.shipping')}</b></Divider>
        <ShippingDetails form={form} expandedIndex={expandedIndex} gift={gift} options={options} setOptions={setOptions}
                         modalOpen={modalOpen} setModalOpen={setModalOpen} onSubmit={onSubmit}/>
    </Flex>;

    return errorPage ?
        <Result
            status="error"
            title={t('error.title')}
            subTitle={t('error.description', {id: params.giftId})}
            extra={<Button onClick={() => navigate("/")} type="primary">{t('button.back')}</Button>}
        /> : loading
            ? <Spin spinning={loading} size={"large"} fullscreen/>
            : searchParams.get('preview') ?
                <Watermark content={t('watermark')}>{getContent()}</Watermark> : getContent()
};

export default ChoosePage;