import React from 'react';
import {Form, Select} from "antd";
import {PHONE_PREFIXES} from "../../config/phonePrefixConst";
import {Option} from "antd/es/mentions";

const PrefixSelector = () => {
    return (
        <Form.Item name="prefix" noStyle>
            <Select
                showSearch
                defaultValue="+48"
            >
                {PHONE_PREFIXES.map((item,index) => <Option key={index} value={item.code}>{item.code} {item.name}</Option>)}
            </Select>
        </Form.Item>
    );
};

export default PrefixSelector;