import React, {useState} from 'react';
import {Divider, Flex, Modal, Slider, Switch} from "antd";
import {ManOutlined, WomanOutlined} from "@ant-design/icons";
import InspirationsContainer from "./InspirationsContainer";
import {getClosest, INSPIRATIONS_MEN, INSPIRATIONS_WOMEN} from "../../config/inspirations";
import {useTranslation} from "react-i18next";

const SuggestionModal = ({open, onCancel}) => {
    const [male, setMale] = useState(true);
    const [age, setAge] = useState([18, 30]);
    const [inspiration, setInspirations] = useState(null);
    const {t} = useTranslation('steps');

    const getInspirations = (isMale, age) => {
        const averageAge = (age[0] + age[1]) / 2
        const closest = parseInt(getClosest(isMale ? Object.keys(INSPIRATIONS_MEN(t)) : Object.keys(INSPIRATIONS_WOMEN(t)), averageAge));
        const text = isMale ? INSPIRATIONS_MEN(t)[closest] : INSPIRATIONS_WOMEN(t)[closest];
        setInspirations(text);
    };

    return (
        <>
            <InspirationsContainer inspiration={inspiration} setInspirations={setInspirations}/>
            <Modal width={400} title={t('inspiration.title')} open={open} onCancel={onCancel} zIndex={10}
                   onOk={() => getInspirations(male, age)}>
                <Flex vertical justify={"center"} align={"center"} style={{width: "100%"}}>

                    <Divider>{t('inspiration.sex')}</Divider>
                    <Switch defaultChecked style={{background: !male && "deeppink"}}
                            onChange={(checked) => setMale(checked)}
                            checkedChildren={<><ManOutlined/> {t('inspiration.men')}</>}
                            unCheckedChildren={<><WomanOutlined/> {t('inspiration.female')}</>}/>
                    <Divider>{t('inspiration.age')}</Divider>
                    <Slider range={{draggableTrack: true}} defaultValue={[18, 30]} onChange={value => setAge(value)}
                            min={1} style={{width: "100%"}}
                            max={100}/>

                </Flex>
            </Modal>
        </>
    );
};

export default SuggestionModal;