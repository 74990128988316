import React from 'react';
import {Button, Flex, Image, Result} from "antd";
import {useNavigate,} from "react-router-dom";
import giftCreate from "../../assets/gift_create.svg";
import {useTranslation} from "react-i18next";

const SuccessContainer = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('choose')

    return (
        <Result
            status="success"
            title={t('success.title')}
            subTitle={<Flex vertical justify={"center"} align={"center"}><b>{t('success.subtitle')}</b>
                <Image
                    src={giftCreate} preview={false} style={{maxWidth: 600}} width={"100%"}/></Flex>}
            extra={[
                <Button onClick={() => navigate("/")} type="primary">{t('success.back')}</Button>,
            ]}
        />
    );
};

export default SuccessContainer;