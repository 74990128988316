import React from 'react';
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";

const CookiePopup = () => {
    const {t} = useTranslation('layout')

    return (
        <CookieConsent
            location="bottom"
            buttonText={t('button.understand')}
            style={{
                background: "white",
                borderRadius: 8,
                color: "gray",
                textAlign: "center",
                width: "calc(100% - 20px)",
                maxWidth: "900px",
                left: "50%",
                bottom: "15%",
                transform: "translate(-50%, -15%)",
                zIndex: 1001,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: '0px 11px 35px 4px rgba(0, 0, 0, 0.3)'
            }}
            buttonStyle={{
                background: "#bc83fc",
                padding: 8,
                color: "white",
                fontSize: "18px",
                borderRadius: "8px"
            }}
            expires={150}
        >
            <p>{t('cookie1')}{t('cookieLearnMore')}</p>

            <p><b>{t('cookie2')}</b></p>
        </CookieConsent>
    );
};

export default CookiePopup;