import React from 'react';
import {Card, Drawer, Flex} from "antd";
import UploadImage from "./UploadImage";
import {useTranslation} from "react-i18next";

const ImageDrawer = ({setOpenDrawer, openDrawer, searchResult, setSearchResult}) => {
    const remove = (arr, forDeletion) => arr.filter(item => !forDeletion.includes(item));
    const {t} = useTranslation('steps');

    return (
        <Drawer size={"large"} title={t('card.image')} placement="right"
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}>
            <Flex gap={"small"} align={"flex-start"} justify={"flex-start"} wrap={'wrap'}>
                {searchResult?.images?.map((img, index) => <Card key={index} hoverable onClick={() => {
                    setSearchResult({
                        ...searchResult,
                        imgIndex: index,
                        img
                    });
                }} style={{border: index === searchResult.imgIndex ? "1px solid green" : "none"}}>
                    <img key={index}
                         src={img}
                         style={{maxWidth: 128}}
                         alt={`Main image number ${index}`}/>
                </Card>)}
                <UploadImage addNewImage={(file) => {
                    const images = searchResult.images.concat(file);
                    setSearchResult({
                        ...searchResult,
                        images,
                        imgIndex: images.length === 1 ? 0 : searchResult.imgIndex
                    });
                }}
                             removeNewImage={(file) => {
                                 const images = remove(searchResult.images, file);
                                 setSearchResult({
                                     ...searchResult,
                                     images,
                                     img: searchResult.img === file ? images[0] : searchResult.img,
                                     imgIndex: searchResult.img === file ? 0 : searchResult.imgIndex
                                 });
                             }}
                />
            </Flex>
        </Drawer>
    );
};

export default ImageDrawer;