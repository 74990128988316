import React from 'react';
import {Image, Modal, Steps, Typography} from "antd";
import Title from "antd/lib/typography/Title";
import styles from "./WelcomeModal.module.css";
import {AnimatePresence, motion} from "framer-motion";
import MovingComponent from "react-moving-text";
import {CodepenCircleOutlined, GiftOutlined, SmileOutlined, SolutionOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
const modalVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
}
const WelcomeModal = ({isModalOpen, toggleModal, title, description, company}) => {
    const {t} = useTranslation('steps');

    return (
        <AnimatePresence>
            <Modal
                style={{overflow: "hidden"}}
                title={<div>
                    <MovingComponent
                        type="bounce"
                        duration="1000ms"
                        delay="0s"
                        direction="normal"
                        timing="ease"
                        iteration="5"
                        fillMode="none">
                        <Title level={1} className={styles.text}>{t("modal.title")}</Title>
                    </MovingComponent>

                    <Title level={3}>{title}</Title>
                </div>}
                open={isModalOpen}
                onOk={() => toggleModal()}
                onCancel={() => toggleModal()}
                footer={(_, {OkBtn}) => (
                    <>
                        <OkBtn/>
                    </>
                )}
                width={1000}
                styles={{
                    content: {
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContext: "space-evenly",
                        overflow: "hidden"
                    }
                }}
                modalRender={modal =>
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={modalVariants}
                        transition={{
                            duration: 1
                        }}
                    >
                        {modal}</motion.div>}
            >
                <Typography>{description}</Typography>
                <Steps direction={"vertical"} current={2}
                       items={[{
                           title: t("modal.steps.chooseTitle"),
                           description: t("modal.steps.chooseDescription"),
                           icon: <GiftOutlined/>
                       },
                           {
                               title: t("modal.steps.fillTitle"),
                               description: t("modal.steps.fillDescription"),
                               icon: <SolutionOutlined/>
                           },
                           {
                               title: t("modal.steps.notificationTitle"),
                               icon: <SmileOutlined/>,
                           }]}/>
                {company && company.image &&
                    <Image src={company.image} alt={`${company.name} Company logo`} style={{maxWidth: 300, padding: 24}}
                           preview={false}/>}
            </Modal>
        </AnimatePresence>
    );
};

export default WelcomeModal;