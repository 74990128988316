import React from 'react';
import {Button, Card, Flex} from "antd";
import add from "../../assets/add.png";
import Search from "antd/es/input/Search";
import {useTranslation} from "react-i18next";

const NewItemPlaceholderCard = ({affix, setAffix, loadingSearch, fetchResult, setSearchResult}) => {
    const {t} = useTranslation('steps');

    return (
        <Card
            hoverable
            style={{
                width: "33 vw",
                height: "100%",
                padding: "12px 12px 0 12px"
            }}
            cover={
                affix ?
                    [<Button block type="primary" size="small" onClick={() => setAffix(false)}>{t('card.newItem.hide')} 👋🤖</Button>] :
                    [<Button block type="dashed" size="small" onClick={() => setAffix(true)}>{t('card.newItem.pin')} 📌📍</Button>]}
        >
            <Flex vertical align={"center"} justify={"center"} gap={"small"}>
            <img alt="Add new item" src={add} style={{width: 64, height: 64}}/>
            <h3>{t('card.newItem.title')}</h3>
            <Search style={{minWidth: "33vw"}} placeholder={t('card.newItem.searchPlaceholder')}
                    enterButton={t('button.search')}
                    size="large" loading={loadingSearch} onSearch={fetchResult}/>
            Or...
            <Button block type={"dashed"} onClick={() => setSearchResult({titles: [], images: []})}>{t('button.customProduct')}</Button>
        </Flex>
        </Card>
    );
};

export default NewItemPlaceholderCard;