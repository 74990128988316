import React from 'react';
import {Card, Popconfirm} from "antd";
import {ReactComponent as Discard} from "../../assets/discard.svg";
import Meta from "antd/es/card/Meta";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

const SingleCard = ({index, expandedIndex, handleCardClick, deleteItem, item}) => {
    const {t} = useTranslation('steps');

    return (
        <motion.div
            key={index}
            initial={{scale: 0}}

            animate={{
                scale: 1,
                transition: {delay: 0.5, type: "spring"}
            }}
            exit={{
                opacity: 0,
                transition: {delay: 0.5}
            }}
            onClick={() => expandedIndex !== index && handleCardClick(index)}
            layout
            transition={{duration: 0.5}}
            style={{
                width: index === expandedIndex ? 300 : 240
            }}>

            <Card
                key={index}
                hoverable
                actions={index === expandedIndex && [
                    <Popconfirm
                        title={t('item.delete.title')}
                        description={t('item.delete.description')}
                        onConfirm={deleteItem}
                        okText={t('button.yes')}
                        cancelText={t('button.no')}
                    >
                        <Discard key="discard" style={{margin: "0 8px", width: 32}}/>
                    </Popconfirm>,
                ]}
                cover={<img alt="example" src={item.images[item.imgIndex]}
                            style={{width: "100%", height: 300, objectFit: "cover"}}
                            onClick={() => handleCardClick(index)}
                />}
            >                    {index === expandedIndex && (
                <Meta style={{
                    overflow: "initial",
                    wordWrap: "break-word", whiteSpace: 'normal !important'
                }} title={<span
                    style={{whiteSpace: 'normal !important', overflow: "initial"}}>{item.title}</span>}
                      description={item.url}/>
            )}
            </Card>

        </motion.div>
    );
};

export default SingleCard;