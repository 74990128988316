import {BACKGROUNDS} from "./constants";

export const REDIRECT_BODY = '{\n' +
    '    "companyId": "1",\n' +
    '    "items": [\n' +
    '        {\n' +
    '            "title": "test",\n' +
    '            "image": "image",\n' +
    '            "url": "optional"\n' +
    '        }\n' +
    '    ]\n' +
    '}';

export const SHARABLE_LINK_BODY = '{\n' +
    '    "companyId": "1",\n' +
    '    "title": "test",\n' +
    '    "description": "optional",\n' +
    '    "items": [\n' +
    '        {\n' +
    '            "title": "test",\n' +
    '            "image": "image",\n' +
    '            "url": "optional"\n' +
    '        }\n' +
    '    ],\n' +
    `    "backgroundType": "NONE | ${Object.keys(BACKGROUNDS).join(" | ")}",\n` +
    '    "phone": "+48123123123"\n' +
    '    "email": "email@gmail.com"\n' +
    '}';