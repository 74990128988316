import React from 'react';
import {Select} from "antd";
import {ReactComponent as Poland} from "../../assets/poland.svg";
import {ReactComponent as UK} from "../../assets/greatBritain.svg";
import i18n from "../../config/i18n";
import style from './LanguageSelector.module.css'

const LanguageSelector = () => {
    const items = [
        {
            value: "en",
            label: <UK style={{width: 24, height: 24, objectFit: "contain", paddingTop: 8}}/>
        },
        {
            value: "pl",
            label: <Poland style={{width: 24, height: 24, objectFit: "contain", paddingTop: 8}}/>
        }];

    return i18n.language &&
        <Select className={style.container} dropdownStyle={{backgroundColor: "#565656"}} defaultValue={(i18n.language && i18n.language.split('-')[0]) || "en"} size={"large"}
                options={items} onSelect={e => i18n.changeLanguage(e)}/>

};

export default LanguageSelector;