import React from 'react';
import {Card, Typography} from "antd";
import {motion} from "framer-motion";

const InformationCard = ({title, cover, description, index}) => {
    return (
        <motion.div whileHover={{scale: 1.2}}
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 2 === 0 ? 50 : -50
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                            duration: 1 // Animation duration
                        }
                    }}
                    viewport={{ once: true }}>
            <Card id="cards" bordered={false} style={{width: 300}} title={<Typography.Title style={{fontSize: 24, color: "#2B4481FF", whiteSpace: 'wrap'}}>{title}</Typography.Title>}
                  cover={cover}>
                {description}
            </Card>
        </motion.div>
    );
};

export default InformationCard;