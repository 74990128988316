import React from 'react';
import styles from "./HomePage.module.css";
import {Card, Flex, List, Typography} from "antd";
import { motion } from "framer-motion";
import classNames from "classnames";

const MiddlePageContainer = ({text, title, icon, index, left, items, innerTitle}) => {
    return (
        <motion.div
            className={styles.integrationContainer}
            initial={{
                opacity: 0,
                // if odd index card,slide from right instead of left
                x: index % 2 === 0 ? 50 : -50
            }}
            whileInView={{
                opacity: 1,
                x: 0, // Slide in to its original position
                transition: {
                    duration: 1 // Animation duration
                }
            }}
            viewport={{ once: true }}
        >
            <Flex vertical justify={"center"} align={"center"}>

                <Flex align={"center"} justify={"center"} wrap={"wrap"} className={styles.flexContainer}>
                    <Typography.Title level={2} className={styles.text}>{title}</Typography.Title>
                    <Card bordered={true} className={styles.card}>
                        {icon && <div className={classNames(styles.icon, {
                            [styles.leftIcon]: left,
                            [styles.rightIcon]: !left
                        })}>{icon}</div>}
                        {innerTitle && <Typography.Title level={3}>{innerTitle}</Typography.Title>}
                        {items &&   <List
                            itemLayout="horizontal"
                            dataSource={items}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <List.Item.Meta
                                        avatar={item.icon}
                                        title={item.title}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                        }
                        {text &&<p>{text}</p>}
                    </Card>
                </Flex>
            </Flex>
        </motion.div>
    );
};

export default MiddlePageContainer;