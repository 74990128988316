export const LOVE =  {
    background: {
        color: {
            value: "#FFF"
        }
    },
    particles: {
        color: {
            value: [
                "#FFAEBC",
                "#A0E7E5",
                "#B4F8C8",
                "#FBE7C6",
                "#FFC9AE",
                "#FFAEE5",
                "#A0C6E7",
                "#A0E7C2",
                "#B4F8EA",
                "#C2F8B4",
                "#F4FBC6",
                "#FBCDC6"
            ]
        },
        move: {
            angle: {
                offset: 0,
                value: 15
            },
            direction: "bottom",
            enable: true,
            outModes: {
                default: "out"
            },
            speed: 3
        },
        number: {
            value: 300
        },
        opacity: {
            value: 1
        },
        shape: {
            type: "heart"
        },
        size: {
            value: 16
        },
        roll: {
            darken: {
                enable: true,
                value: 30
            },
            enlighten: {
                enable: true,
                value: 30
            },
            enable: true,
            mode: "horizontal",
            speed: {
                min: 5,
                max: 15
            }
        },
        zIndex: {
            value: {
                min: 0,
                max: 100
            },
            opacityRate: 0,
            velocityRate: 2
        }
    }
};

const emitterRate = {
        delay: 0.5,
        quantity: 2,
    };
export const EMOJI = {
        name: "Shape Emoji",
        particles: {
            opacity: {
                value: 1,
            },
            size: {
                value: {
                    min: 16,
                    max: 32,
                },
            },
            move: {
                enable: true,
                gravity: {
                    enable: true,
                },
                speed: 15,
                outModes: {
                    default: "destroy",
                    top: "none",
                },
            },
            rotate: {
                value: {
                    min: 0,
                    max: 360,
                },
                direction: "random",
                move: true,
                animation: {
                    enable: true,
                    speed: 60,
                },
            },
            tilt: {
                direction: "random",
                enable: true,
                move: true,
                value: {
                    min: 0,
                    max: 360,
                },
                animation: {
                    enable: true,
                    speed: 60,
                },
            },
            roll: {
                darken: {
                    enable: true,
                    value: 30,
                },
                enlighten: {
                    enable: true,
                    value: 30,
                },
                enable: true,
                mode: "both",
                speed: {
                    min: 15,
                    max: 25,
                },
            },
            wobble: {
                distance: 30,
                enable: true,
                move: true,
                speed: {
                    min: -15,
                    max: 15,
                },
            },
        },
        background: {
            color: "#ffffff",
        },
        emitters: [
            {
                position: {
                    x: 0,
                    y: 33,
                },
                rate: emitterRate,
                particles: {
                    move: {
                        direction: "topRight",
                    },
                    shape: {
                        type: "emoji",
                        options: {
                            emoji: {
                                value: "🦄",
                            },
                        },
                    },
                },
            },
            {
                position: {
                    x: 0,
                    y: 66,
                },
                rate: emitterRate,
                particles: {
                    move: {
                        direction: "topRight",
                    },
                    shape: {
                        type: "emoji",
                        options: {
                            emoji: {
                                value: "🌈",
                            },
                        },
                    },
                },
            },
            {
                position: {
                    x: 100,
                    y: 33,
                },
                rate: emitterRate,
                particles: {
                    move: {
                        direction: "topLeft",
                    },
                    shape: {
                        type: "emoji",
                        options: {
                            emoji: {
                                value: "🎉",
                            },
                        },
                    },
                },
            },
            {
                position: {
                    x: 100,
                    y: 66,
                },
                rate: emitterRate,
                particles: {
                    move: {
                        direction: "topLeft",
                    },
                    shape: {
                        type: "emoji",
                        options: {
                            emoji: {
                                value: "🤡",
                            },
                        },
                    },
                },
            },
            {
                position: {
                    x: 50,
                    y: 50,
                },
                rate: emitterRate,
                particles: {
                    move: {
                        direction: "top",
                    },
                    shape: {
                        type: "emoji",
                        options: {
                            emoji: {
                                value: "💩",
                            },
                        },
                    },
                },
            },
        ],
};

export const SNOW = {
    background: {
        color: "rgba(111,111,111,0.51)"
    },
    particles: {
        color: {
            value: "#fff"
        },
        number: {
            value: 50
        },
        opacity: {
            value: {min: 0.3, max: 1}
        },
        shape: {
            type: "circle"
        },
        size: {
            value: {min:1, max:5}
        },
        move: {
            direction: "bottom-right",
            enable: true,
            speed: {min: 3, max: 5},
            straight: true
        }
    }
}

export const HEXAGON_PATHS = {
    name: "Hexagon Path",
    particles: {
        color: {
            value: "#FF0000",
            animation: {
                enable: true,
                speed: 10,
            },
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "destroy",
            },
            path: {
                clamp: false,
                enable: true,
                delay: {
                    value: 0,
                },
                generator: "polygonPathGenerator",
                options: {
                    sides: 6,
                    turnSteps: 30,
                    angle: 30,
                },
            },
            speed: 3,
            trail: {
                fill: { color: "#ffffff" },
                length: 20,
                enable: true,
            },
        },
        number: {
            density: {
                enable: true,
            },
            value: 0,
        },
        opacity: {
            value: 1,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: 2,
        },
    },
    background: {
        color: "#ffffff",
    },
    emitters: {
        direction: "none",
        rate: {
            quantity: 1,
            delay: 0.25,
        },
        size: {
            width: 0,
            height: 0,
        },
        position: {
            x: 50,
            y: 50,
        },
    },
};

export const GIFS = {
    name: "Gifs",
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push",
            },
        },
        modes: {
            push: {
                quantity: 4,
            },
        },
    },
    particles: {
        color: {
            value: "#ffffff",
        },
        move: {
            enable: true,
            speed: { min: 1, max: 6 },
        },
        number: {
            value: 20,
            limit: { value: 30 },
        },
        opacity: {
            value: 1,
        },
        rotate: {
            path: true,
        },
        shape: {
            options: {
                image: {
                    gif: true,
                    height: 200,
                    src: "https://particles.js.org/images/mario.gif",
                    width: 200,
                },
            },
            type: "image",
        },
        size: {
            value: {
                min: 32,
                max: 64,
            },
        },
    },
};

export const FIREWORK_2 = {
    name: "Fireworks 2",
    fullScreen: {
        enable: true,
    },
    background: {
        color: "#ffffff",
        image: "url('https://particles.js.org/images/background3.jpg')",
        position: "50% 50%",
        repeat: "no-repeat",
        size: "cover",
    },
    backgroundMask: {
        enable: true,
    },
    emitters: {
        direction: "top",
        life: {
            count: 0,
            duration: 0.1,
            delay: 0.1,
        },
        rate: {
            delay: 0.15,
            quantity: 1,
        },
        size: {
            width: 100,
            height: 0,
        },
        position: {
            y: 100,
            x: 50,
        },
    },
    particles: {
        color: {
            value: "#fff",
        },
        number: {
            value: 0,
        },
        destroy: {
            bounds: {
                top: 30,
            },
            mode: "split",
            split: {
                count: 1,
                factor: {
                    value: 0.333333,
                },
                rate: {
                    value: 100,
                },
                particles: {
                    stroke: {
                        width: 0,
                    },
                    color: {
                        value: ["#ff595e", "#ffca3a", "#8ac926", "#1982c4", "#6a4c93"],
                    },
                    number: {
                        value: 0,
                    },
                    collisions: {
                        enable: false,
                    },
                    destroy: {
                        bounds: {
                            top: 0,
                        },
                    },
                    opacity: {
                        value: {
                            min: 0.1,
                            max: 1,
                        },
                        animation: {
                            enable: true,
                            speed: 0.7,
                            sync: false,
                            startValue: "max",
                            destroy: "min",
                        },
                    },
                    effect: {
                        type: "trail",
                        options: {
                            trail: {
                                length: {
                                    min: 5,
                                    max: 10,
                                },
                            },
                        },
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: 2,
                        animation: {
                            enable: false,
                        },
                    },
                    life: {
                        count: 1,
                        duration: {
                            value: {
                                min: 1,
                                max: 2,
                            },
                        },
                    },
                    move: {
                        enable: true,
                        gravity: {
                            enable: true,
                            acceleration: 9.81,
                            inverse: false,
                        },
                        decay: 0.1,
                        speed: {
                            min: 10,
                            max: 25,
                        },
                        direction: "outside",
                        outModes: "destroy",
                    },
                },
            },
        },
        life: {
            count: 1,
        },
        effect: {
            type: "trail",
            options: {
                trail: {
                    length: {
                        min: 10,
                        max: 30,
                    },
                    minWidth: 1,
                    maxWidth: 1,
                },
            },
        },
        rotate: {
            path: true,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: 1,
        },
        move: {
            enable: true,
            gravity: {
                acceleration: 15,
                enable: true,
                inverse: true,
                maxSpeed: 100,
            },
            speed: {
                min: 10,
                max: 20,
            },
            outModes: {
                default: "destroy",
                top: "none",
            },
        },
    },
};

export const FIREWORK = {
    name: "Fireworks",
    fullScreen: {
        enable: true,
    },
    background: {
        color: "#ffffff",
    },
    emitters: {
        direction: "top",
        life: {
            count: 0,
            duration: 0.1,
            delay: 0.1,
        },
        rate: {
            delay: 0.15,
            quantity: 1,
        },
        size: {
            width: 100,
            height: 0,
        },
        position: {
            y: 100,
            x: 50,
        },
    },
    particles: {
        number: {
            value: 0,
        },
        destroy: {
            bounds: {
                top: 30,
            },
            mode: "split",
            split: {
                count: 1,
                factor: {
                    value: 0.333333,
                },
                rate: {
                    value: 100,
                },
                particles: {
                    stroke: {
                        width: 0,
                    },
                    color: {
                        value: ["#ff595e", "#ffca3a", "#8ac926", "#1982c4", "#6a4c93"],
                    },
                    number: {
                        value: 0,
                    },
                    collisions: {
                        enable: false,
                    },
                    destroy: {
                        bounds: {
                            top: 0,
                        },
                    },
                    opacity: {
                        value: {
                            min: 0.1,
                            max: 1,
                        },
                        animation: {
                            enable: true,
                            speed: 0.7,
                            sync: false,
                            startValue: "max",
                            destroy: "min",
                        },
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: 2,
                        animation: {
                            enable: false,
                        },
                    },
                    life: {
                        count: 1,
                        duration: {
                            value: {
                                min: 1,
                                max: 2,
                            },
                        },
                    },
                    move: {
                        enable: true,
                        gravity: {
                            enable: true,
                            acceleration: 9.81,
                            inverse: false,
                        },
                        decay: 0.1,
                        speed: {
                            min: 10,
                            max: 25,
                        },
                        direction: "outside",
                        outModes: "destroy",
                    },
                },
            },
        },
        life: {
            count: 1,
        },
        shape: {
            type: "line",
        },
        size: {
            value: {
                min: 0.1,
                max: 50,
            },
            animation: {
                enable: true,
                sync: true,
                speed: 90,
                startValue: "max",
                destroy: "min",
            },
        },
        stroke: {
            color: {
                value: "#ff8484",
            },
            width: 1,
        },
        rotate: {
            path: true,
        },
        move: {
            enable: true,
            gravity: {
                acceleration: 15,
                enable: true,
                inverse: true,
                maxSpeed: 100,
            },
            speed: {
                min: 10,
                max: 20,
            },
            outModes: {
                default: "destroy",
                top: "none",
            },
            trail: {
                fill: { color: "#ffffff" },
                enable: true,
                length: 10,
            },
        },
    },
};

export const CONFFETI = {
    detectRetina: true,
    fullScreen: {
        enable: true,
        zIndex: -1
    },
    interactivity: {
        detectsOn: "window"
    },
    emitters: {
        position: {
            x: 50,
            y: 100
        },
        rate: {
            quantity: 10,
            delay: 0.25
        }
    },
    particles: {
        color: {
            value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"]
        },
        move: {
            decay: 0.01,
            direction: "top",
            enable: true,
            gravity: {
                enable: true,
                maxSpeed: 150
            },
            outModes: {
                top: "none",
                default: "destroy"
            },
            speed: { min: 25, max: 50 }
        },
        number: {
            value: 0
        },
        opacity: {
            value: 1
        },
        rotate: {
            value: {
                min: 0,
                max: 360
            },
            direction: "random",
            animation: {
                enable: true,
                speed: 30
            }
        },
        tilt: {
            direction: "random",
            enable: true,
            value: {
                min: 0,
                max: 360
            },
            animation: {
                enable: true,
                speed: 30
            }
        },
        size: {
            value: 8
        },
        roll: {
            darken: {
                enable: true,
                value: 25
            },
            enable: true,
            speed: {
                min: 5,
                max: 15
            }
        },
        wobble: {
            distance: 30,
            enable: true,
            speed: {
                min: -7,
                max: 7
            }
        },
        shape: {
            type: [
                "circle",
                "square",
                "polygon",
                "character",
                "character",
                "character",
                "image",
                "image",
                "image"
            ],
            options: {
                image: [
                    {
                        src: "https://particles.js.org/images/fruits/apple.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/avocado.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/banana.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/berries.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/cherry.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/grapes.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/lemon.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/orange.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/peach.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/pear.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/pepper.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/plum.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/star.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/strawberry.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src: "https://particles.js.org/images/fruits/watermelon.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    },
                    {
                        src:
                            "https://particles.js.org/images/fruits/watermelon_slice.png",
                        width: 32,
                        height: 32,
                        particles: {
                            size: {
                                value: 16
                            }
                        }
                    }
                ],
                polygon: [
                    {
                        sides: 5
                    },
                    {
                        sides: 6
                    }
                ],
                character: [
                    {
                        fill: true,
                        font: "Verdana",
                        value: ["💩", "🤡", "🍀", "🍙", "🦄", "⭐️"],
                        style: "",
                        weight: 400
                    }
                ]
            }
        }
    }
}

export const BUBBLES = {
    fpsLimit: 120,
    particles: {
        color: {
            value: "random",
        },
        shape: {
            type: "circle",
        },
        opacity: {
            value: 0.3,
        },
        size: {
            value: { min: 5, max: 10 },
        },
        move: {
            angle: {
                offset: 0,
                value: 30,
            },
            enable: true,
            speed: 15,
            direction: 'top',
            random: false,
            straight: false,
            outModes: {
                default: 'destroy',
            },
        },
    },
    detectRetina: true,
    emitters: [
        {
            direction: 'top',
            position: {
                x: 50,
                y: 100
            },
            life: {
                duration: 20,
                delay: 10,
                count: 0,
            },
        },
    ],
};

export const BACKGROUNDS = {
    FIREWORK: {
        config: FIREWORK,
        name: 'Firework'
    },
    FIREWORK_2: {
        config: FIREWORK_2,
        name: 'Firework 2'
    },
    CONFETTI: {
        config: CONFFETI,
        name: 'Confetti'
    },
    SNOW: {
        config: SNOW,
        name: 'Snow'
    },
    GIFS: {
        config: GIFS,
        name: 'Gifs'
    },
    EMOJI: {
        config: EMOJI,
        name: 'Emoji'
    },
    HEXAGON_PATHS: {
        config: HEXAGON_PATHS,
        name: 'Hexagon Paths'
    },
    BUBBLES: {
        config: BUBBLES,
        name: 'Bubbles'
    },
    IMAGES: {
        config: LOVE,
        name: 'Love'
    }
}
