import React from 'react';
import {Card, Flex} from "antd";
import {Trans, useTranslation} from "react-i18next";

const PrivacyPolicyPage = () => {
    const {t} = useTranslation('policy');

    return (
        <Flex align={"center"} justify={"center"}>
            <Card style={{maxWidth: 900, margin: 24}}>
                <Trans t={t} i18nKey="rules" components={{1: <h3/>, 2: <li/>, 3: <ol style={{margin: "24px 0"}}/>}}/>
            </Card>
        </Flex>
    );
};

export default PrivacyPolicyPage;