
export const INSPIRATIONS_MEN = t => ({
    10: t('inspiration.text.men.10'),
    18: t('inspiration.text.men.18'),
    30: t('inspiration.text.men.30'),
    50: t('inspiration.text.men.50'),
    70: t('inspiration.text.men.70')
})

export const INSPIRATIONS_WOMEN = t => ({
    10: t('inspiration.text.female.10'),
    18: t('inspiration.text.female.18'),
    30: t('inspiration.text.female.30'),
    50: t('inspiration.text.female.50'),
    70: t('inspiration.text.female.70')
})

export const getClosest = (counts, goal) => counts.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));