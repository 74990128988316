import React from 'react';
import {Button, Flex, Result, Typography} from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import presentAnimation from "../../assets/Animation - 1705411779434.json";
import {useTranslation} from "react-i18next";

const SuccessContainer = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {t} = useTranslation('steps');

    return (
        searchParams.get("giftUrl") && searchParams.get("giftId") ?
            <Result
                status="success"
                title={t('success.title')}
                subTitle={
                    <Flex vertical justify={"center"} align={"center"}>{t('success.subtitle')}
                        <Typography.Text copyable keyboard strong style={{
                            fontSize: 24,
                            margin: "12px 0"
                        }}>{searchParams.get("giftUrl")}</Typography.Text>
                        <Link to={`/choose/${searchParams.get("giftId")}?preview=true`}>{t('success.preview')} {searchParams.get("giftId")}</Link>
                        <Lottie animationData={presentAnimation} loop={true} width={400} />
                    </Flex>
                }
                extra={[
                    <Button onClick={() => navigate("/")} type="primary">{t('button.back')}</Button>,
                    <Button onClick={() => navigate(`/choose/${searchParams.get("giftId")}?preview=true`)}>{t('button.preview')}</Button>,
                ]}
            />
            :
            <Result
    status="error"
    title={t('success.error')}
    />
    );
};

export default SuccessContainer;