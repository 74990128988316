import React, {useEffect, useState} from 'react';
import {Button, Flex, Image, message, Result, Spin} from "antd";
import {useNavigate,} from "react-router-dom";
import giftCreate from "../../assets/gift_create.svg";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import HttpService from "../../service/HttpService";

const SuccessContainer = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('choose')
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(true);

    useEffect(() => {
        HttpService.deleteGift(params.giftId)
            .catch(error => {
                console.error(error);
                setSuccess(false);
                message.error(t('notification.error'));
            })
            .finally(() => setLoading(false));
    }, [params, t])

    if (loading) {
        return <Spin size={'large'} spinning={loading} fullscreen/>
    }
    
    return (
        <Result
            status={success ? "success" : "error"}
            title={t('delete.title')}
            subTitle={<Flex vertical justify={"center"} align={"center"}><b>{t('delete.subtitle')}</b>
                <Image
                    src={giftCreate} preview={false} style={{maxWidth: 600}} width={"100%"}/></Flex>}
            extra={[
                <Button onClick={() => navigate("/")} type="primary">{t('success.back')}</Button>,
            ]}
        />
    );
};

export default SuccessContainer;