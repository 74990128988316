import React, {useEffect, useRef} from 'react';
import {Divider, Flex, Form, Image, Input, Modal} from "antd";
import customize from "../../assets/10782767_19197303.jpg";
import {useTranslation} from "react-i18next";

const useResetFormOnCloseModal = ({form, open}) => {
    const prevOpenRef = useRef();

    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const ChooseFormOptions = ({open, onCancel, options, setValues}) => {
    const [form] = Form.useForm();
    const {t} = useTranslation('choose')

    useResetFormOnCloseModal({
        form,
        open,
    });

    const onOk = () => {
        form.submit();
    };

    const onChange = name => e => {
        form.setFieldValue(name, e.target.value);
    }

    return (
        <Modal title={t('options.title')} open={open} onOk={onOk} onCancel={onCancel}>
            <Flex justify={"center"}><Image preview={false} width={300} src={customize}/></Flex>
            <Divider>{t('options.divider')}</Divider>
            <Form form={form} layout="vertical" autoComplete="off" onFinish={setValues}>
                {options.includes("size") &&
                    <Form.Item
                        name="size"
                        label={t('options.sizeLabel')}
                        rules={[
                            {
                                required: true,
                                max: 20,
                                message: t('options.sizeRule')
                            }
                        ]}
                        style={{width: "100%"}}
                    > <Input onChange={onChange("size")}/>
                    </Form.Item>
                }
                {options.includes("color") && <Form.Item
                    name="color"
                    label={t('options.colorLabel')}
                    rules={[
                        {
                            required: true,
                            max: 20,
                            message: t('options.colorRule')
                        }
                    ]}
                    style={{width: "100%"}}
                > <Input onChange={onChange("color")}/>
                </Form.Item>}
                {options.includes("note") &&
                    <Form.Item
                        name="note"
                        label={t('options.noteLabel')}
                        rules={[
                            {
                                required: true,
                                max: 50,
                                message: t('options.noteRule')
                            }
                        ]}
                        style={{width: "100%"}}
                    > <Input onChange={onChange("note")}/>
                    </Form.Item>
                }
            </Form>
        </Modal>
    );
};

export default ChooseFormOptions;