import axios from "axios";
import {BASE_URL} from "../config/URL";


class HttpService {
    constructor() {
        const client = axios.create({baseURL: BASE_URL});
        this.client = client;
    }

    fetchGiftDetails = (url) => this.client.get("/gift?searchedUrl=" + url);

    getGift = id => this.client.get(`/gift/${id}`);

    createGift = (item) => this.client.post("/gift", item);

    getPresignedUrl = (fileName, fileType) => this.client(`/image?fileName=${fileName}&fileType=${fileType}`);

    uploadFile = (url, file, options) => this.client.put(url, file, options);

    chooseGift = (item) => this.client.post("/gift/choose", item);

    deleteGift = (id) => this.client.delete(`/gift/${id}`)
}

export default new HttpService();