import React, {useEffect, useState} from 'react';
import {Button, Drawer, Flex, Menu} from "antd";
import MenuItem from "antd/es/menu/MenuItem";
import {Link, useLocation} from "react-router-dom";
import Logo from "./Logo";
import {Header} from "antd/es/layout/layout";
import {CloseOutlined, MenuOutlined} from "@ant-design/icons";
import styles from "./Navbar.module.css";
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";

const Navbar = () => {
    const [visible, setVisible] = useState(false);
    const {t} = useTranslation('layout');
    const showDrawer = () => {
        setVisible(!visible);
    };

    // If you do not want to auto-close the mobile drawer when a path is selected
    // Delete or comment out the code block below
    // From here
    let {pathname: location} = useLocation();
    useEffect(() => {
        setVisible(false);
    }, [location]);
    // Upto here

    return (
        <Header className={styles.header}>
            <Menu
                theme="dark"
                mode="horizontal"
                style={{fontWeight: 700, width: "100%"}}
            >
                <Flex align={"center"} justify={"space-between"} wrap={"wrap"} style={{width: "100%"}}>
                    <MenuItem title={'Gift chooser'} key={1} label={"as"}
                              style={{backgroundColor: 'initial', fontSize: 28}}>
                        <Link style={{color: "#bc83fc"}} to={"/"}><Logo/></Link>
                    </MenuItem>
                    <Button className={styles.menuButton} type="text" onClick={showDrawer}>
                        <MenuOutlined/>
                    </Button>

                    <div className={styles.rightMenu}>
                        <MenuItem title={'Integration'} key={2} style={{backgroundColor: 'initial', fontSize: 18}}>
                            <Link to={"/integration"}>{t('integration')}</Link>
                        </MenuItem>
                        <LanguageSelector/>
                    </div>
                </Flex>
            </Menu>
            <Drawer
                title={<Logo onClick={showDrawer}/>}
                closeIcon={<CloseOutlined style={{color: "white"}}/>}
                placement="right"
                closable={true}
                open={visible}
                onClose={showDrawer}
                style={{zIndex: 10}}
                classNames={{body: styles.drawer, header: styles.drawer}}
            > <Menu
                theme="dark"
                mode="inline"
                style={{fontWeight: 700, width: "100%"}}
            >
                <MenuItem title={'Integration'} key={2} style={{backgroundColor: 'initial', fontSize: 18}}>
                    <Link to={"/integration"}>{t('integration')}</Link>
                </MenuItem>
                <MenuItem title={'Contact'} key={3} style={{backgroundColor: 'initial', fontSize: 18}}>
                    <Link to={"/contact"}>{t('contact')}</Link>
                </MenuItem>
                <LanguageSelector/>
            </Menu>
            </Drawer>
        </Header>

    );
};

export default Navbar;