import React from 'react';
import {Button, Card, Flex, Typography} from "antd";
import {motion} from "framer-motion";
import Meta from "antd/es/card/Meta";
import styles from "./ChooseItemsContainer.module.css";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

const ChooseItemsContainer = ({items, handleCardClick, expandedIndex, hasError, openModal}) => {
    const {t} = useTranslation('choose')

    return (
        <Flex gap={"large"} align={"flex-start"} justify={"space-evenly"} wrap={'wrap'}
              style={{padding: 24, width: "100%"}}>
            {items.map((item, index) => (
                <motion.div
                    key={index}
                    initial={{scale: 0}}

                    animate={{
                        scale: 1,
                        transition: {delay: 0.5, type: "spring"}
                    }}
                    exit={{
                        opacity: 0,
                        transition: {delay: 0.5}
                    }}

                    layout
                    transition={{duration: 0.5}}
                    onClick={() => handleCardClick(index)}
                    style={{
                        width: index === expandedIndex ? 300 : 240,
                    }}>
                    <Card
                        hoverable
                        className={index === expandedIndex ? styles.chosen : styles.unchosenStyle}
                        cover={<img alt="Item main image" src={item.image} className={index === expandedIndex ? styles.chosenImg : styles.unchosenImg} />}
                        actions={hasError && index === expandedIndex && [<Text type="warning">{t('items.warning')}</Text>,
                            <Button onClick={openModal} type={"dashed"}>{t('button.form')}</Button>]}
                    >
                        {index === expandedIndex && (
                            <Flex vertical align={"center"} justify={"center"}>
                                <Meta style={{
                                    textOverflow: "ellipsis",
                                    wordWrap: "break-word",

                                }} title={<span style={{whiteSpace: "pre-wrap"}}>{item.title}</span>}
                                      description={item.url}/>
                            </Flex>
                        )}
                    </Card>

                </motion.div>
            ))}
        </Flex>
    );
};

export default ChooseItemsContainer;