import React from 'react';
import styles from "./IntegrationChoosePreviewPage.module.css";
import {Divider} from "antd";
import classNames from "classnames";

const defaultItems = [
    {
        title: "Descriptive Title",
        url: "Link to shop",
        img: "https://images.pexels.com/photos/3353621/pexels-photo-3353621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=375&w=630"
    },
    {
        title: "Descriptive Title",
        url: "Link to shop",
        img: "https://images.pexels.com/photos/3353621/pexels-photo-3353621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=375&w=630"
    },
    {
        title: "Descriptive Title",
        url: "Link to shop",
        img: "https://images.pexels.com/photos/3353621/pexels-photo-3353621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=375&w=630"
    },
    {
        title: "Descriptive Title",
        url: "Link to shop",
        img: "https://images.pexels.com/photos/3353621/pexels-photo-3353621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=375&w=630"
    }
]

const Carousel = ({reference, items = defaultItems, selectedIndex = 1, onSelect}) => {
    return (
        <section className={styles.carousel}>
            <div className={styles.carousel__container}>
                {items.map((item, index) =>
                    <div className={classNames(styles.carouselItem, {[styles.carouselItemExpand]: selectedIndex === index})}
                         key={index}
                         onClick={() => onSelect(index)}
                         ref={index === 1 ? reference : null}>
                        <img
                            className={styles.carouselItem__img}
                            src={item.img}
                            alt={item.title}
                        />
                        <div className={styles.carouselItem__details}>
                            <div className={styles.controls}>
                                <span className="fas fa-play-circle"></span>
                                <span className="fas fa-plus-circle"></span>
                            </div>
                            <h5 className={styles.carouselItem__detailsTitle}>{item.title}</h5>
                            <h6 className={styles.carouselItem__detailsSubtitle}>{item.url}</h6>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Carousel;