import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import {Button, Card, Col, Form, Input, message, Row, Space, Typography} from "antd";
import styles from "./ContactPage.module.css";
import {FacebookOutlined, LinkedinOutlined, TwitterOutlined} from "@ant-design/icons";
import blob from '../../assets/blob.svg';
import {motion} from "framer-motion";
import {EMAIL, NAME, PHONE} from "../../config/contact";
import {Trans, useTranslation} from "react-i18next";

const ContactPage = () => {
    const form = useRef();
    const {t} = useTranslation('contact');

    const sendEmail = (values) => {
        emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, values, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {}, (error) => {
                console.error(error.text);
                message.error("Error occurred during sending email message. Try again please!")
            });
    };

    return (
        <motion.div initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                    exit={{opacity: 0, transition: {duration: 1}}}
                    variants={{visible: {transition: {staggerChildren: 0.3}}}}
                    transition={{duration: 1}}>
            <Row justify={"center"} gutter={[24, 24]} className={styles.container} wrap>
                <img className={styles.blob} src={blob} alt={'Background blob'}/>
                <img className={styles.blobReversed} src={blob} alt={'Background blob'}/>
                <Col md={{span: 24}} lg={{span: 12}} flex="1 1 400px">
                    <Card>
                        <Row justify={"center"}>
                            <Col span={12} className={styles.centerText}>
                                <Typography.Title level={3}>{t('contact')}</Typography.Title>
                                <Space direction="vertical" size="large" style={{display: 'flex'}}>

                                    <Typography.Text className={styles.centerText} copyable>{NAME}</Typography.Text>
                                    <Typography.Text>
                                        {t('phone')}: <Typography.Text className={styles.centerText} copyable>{PHONE}</Typography.Text>
                                    </Typography.Text>

                                    <Typography.Text>
                                        E-mail: <Typography.Text className={styles.centerText}
                                                                 copyable>{EMAIL}</Typography.Text>
                                    </Typography.Text>

                                    <Space direction={"horizontal"} size={"large"} className={styles.icon}>
                                        <FacebookOutlined/>
                                        <LinkedinOutlined/>
                                        <TwitterOutlined/>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col md={{span: 24}} lg={{span: 12}} flex="1 1 400px">
                    <Form layout="vertical" autoComplete="off" size={"large"} ref={form} onFinish={sendEmail}
                          className={styles.form}>
                        <Form.Item label={t('nameLabel')}
                                   name="user_name"
                                   rules={[
                                       {
                                           required: true,
                                           max: 100,
                                           message: t('nameValidation')
                                       }]
                                   }
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label={t('emailLabel')}
                                   name="user_email"
                                   rules={[
                                       {
                                           type: 'email',
                                           required: true,
                                           max: 100,
                                           message: t('emailValidation')
                                       },
                                   ]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label={t('messageLabel')}
                            help={<p>{t('messageHelp')}</p>}
                            rules={[
                                {
                                    required: true,
                                    max: 600,
                                    message: t('messageValidation')
                                },
                            ]}
                        >
                            <Input.TextArea allowClear showCount rows={10}/>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t('send')}
                        </Button>
                    </Form>
                </Col>
                <Col span={24}>
                    <Card>
                        <Typography.Title level={3}>{t('title')}</Typography.Title>
                        <Trans t={t} i18nKey="description" components={{ 1: <br /> }} />
                    </Card>
                </Col>
            </Row>
        </motion.div>
    );
};

export default ContactPage;