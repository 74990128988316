import React from 'react';
import styles from './Logo.module.css';
import {GiftOutlined} from "@ant-design/icons";

const Logo = ({onClick}) => {
    return (
        <div className={styles.kufam} onClick={onClick}>
            <span className={styles.textColor}>Gift</span>Pick<GiftOutlined className={styles.icon} />
        </div>
    );
};

export default Logo;