import React from 'react';
import { Button, Result } from "antd";
import { useRouteError } from "react-router";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    const error = useRouteError();
    console.error(error);

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button onClick={() => navigate("/")} type="primary">Back Home</Button>}
        />
    );
};

export default ErrorPage;