import React, {useState} from 'react';
import {Affix, Card, Empty, Flex, FloatButton, Form, message, Spin, Typography} from "antd";
import Meta from "antd/es/card/Meta";
import {AddNewCard} from "./AddNewCard";
import HttpService from "../../service/HttpService";
import {GiftFilled} from "@ant-design/icons";
import SuggestionModal from "./SuggestionModal";
import animationLoading from '../../assets/AnimationLoading.json';
import adGraphic from '../../assets/recommendation.jpg';
import Lottie from "lottie-react";
import SingleCard from "./SingleCard";
import {useTranslation} from "react-i18next";

const CardContainer = ({stepForm, onChange, items, setItems, companyIntegrationEnabled}) => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [affix, setAffix] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const {t} = useTranslation('steps');

    const handleCardClick = (index) => {
        setExpandedIndex(index === expandedIndex ? -1 : index)
    }

    const fetchResult = (searchedUrl) => {
        setLoadingSearch(true);
        HttpService.fetchGiftDetails(searchedUrl)
            .then(({data}) => {
                if (data) {
                    setSearchResult(data);
                } else {
                    message.error(t('notification.fetch'));
                }
            })
            .catch(error => {
                console.error(error);
                message.error(t('notification.fetch'));
            })
            .finally(() => setLoadingSearch(false));
    }

    const AdCard = () => !companyIntegrationEnabled &&
        <Card hoverable headStyle={{opacity: 0.5}}
              bodyStyle={{opacity: 0.5, textAlign: "center", wordWrap: "break-word", width: 300}}
              cover={<img style={{width: 240, height: 230, opacity: 0.5, margin: "0 auto"}}
                          src={adGraphic}
                          alt={'Ad graphic'}/>}
              style={{border: "3px solid #fff"}}
        >
            <Meta title={<span style={{overflow: "initial", wordWrap: "break-word", whiteSpace: 'normal'}}>
                {t('card.adTitle')}
            </span>}
                  description={t('card.adDescription')}/>
        </Card>;

    const AddNewCardContainer = () => {
        if (!companyIntegrationEnabled) {
            const addNewCard = <AddNewCard searchResult={searchResult} loadingSearch={loadingSearch}
                                           setSearchResult={setSearchResult} affix={affix} fetchResult={fetchResult}
                                           setAffix={setAffix} setItems={setItems} items={items}/>;

            return <Form form={stepForm} layout="vertical" autoComplete="off">
                {
                    affix ?
                        <Affix offsetBottom={0}>{addNewCard}</Affix>
                        : addNewCard
                }
            </Form>;
        }
    };

    const deleteSingleItem = () => {
        if (expandedIndex > items.length - 1) {
            return;
        }
        const tempItem = items[expandedIndex]
        onChange(items.filter(item => item !== tempItem));
        setItems(items => items.filter(item => item !== tempItem))
    };

    return (
        <>
            <Spin size={'large'} spinning={loadingSearch} indicator={
                <Lottie animationData={animationLoading} loop={true} style={{transform: 'scale(5)'}}/>}>
                <Flex gap={"large"} align={"flex-start"} justify={"space-evenly"} wrap={'wrap'}
                      style={{padding: 24, width: "100%", minWidth: '90vw'}}>
                    {AdCard()}
                    {items?.length > 0 ? items.map((item, index) =>
                        <SingleCard
                            key={index}
                            index={index}
                            item={item}
                            setItems={setItems}
                            onChange={onChange}
                            handleCardClick={handleCardClick}
                            expandedIndex={expandedIndex}
                            deleteItem={deleteSingleItem}
                        />
                    ) : companyIntegrationEnabled && <Empty />}
                    {AddNewCardContainer()}
                </Flex>
                <FloatButton
                    icon={<GiftFilled/>}
                    description={<Typography.Text style={{fontSize: "1.5em"}}>{t('inspiration.description')}</Typography.Text>}
                    shape="square"
                    style={{
                        filter: 'drop-shadow(0px 0px 10px rgba(219, 59, 255, 0.5))', padding: 24, fontWeight: 700,
                        right: 24, width: "auto", height: "auto",
                    }}
                    onClick={() => setModalOpen(true)}
                />
                <SuggestionModal open={modalOpen} onCancel={() => setModalOpen(false)}/>
            </Spin>
        </>
    );
};

export default CardContainer;