
export const OCCASIONS = t => [
    {
        value: t("steps:init.occasion.birthday"),
        label: t("steps:init.occasion.birthday")
    },
    {
        value: t("steps:init.occasion.valentines"),
        label: t("steps:init.occasion.valentines")
    },
    {
        value: t("steps:init.occasion.christmas"),
        label: t("steps:init.occasion.christmas")
    },
    {
        value: t("steps:init.occasion.anniversary"),
        label: t("steps:init.occasion.anniversary")
    },
    {
        value: t("steps:init.occasion.promotion"),
        label: t("steps:init.occasion.promotion")
    },
    {
        value: t("steps:init.occasion.graduation"),
        label: t("steps:init.occasion.graduation")
    },
    {
        value: t("steps:init.occasion.team"),
        label: t("steps:init.occasion.team")
    }
]