import {motion} from "framer-motion";
import React, {useState} from "react";
import ImageDrawer from "./ImageDrawer";
import NewItemPlaceholderCard from "./NewItemPlaceholderCard";
import AddNewCardForm from "./AddNewCardForm";

export const AddNewCard = ({
                               searchResult,
                               setItems,
                               items,
                               setSearchResult,
                               setAffix,
                               affix,
                               loadingSearch,
                               fetchResult
                           }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [title, setTitle] = useState('');

    const onChange = (checkedValues) => {
        setSearchResult(searchResult => ({...searchResult, options: checkedValues}));
    };

    return <>
        {!loadingSearch && searchResult &&
            <ImageDrawer searchResult={searchResult} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}
                         setSearchResult={setSearchResult}/>}
        <motion.div
            initial={{scale: 0}}
            animate={{
                scale: 1,
                transition: {delay: 0.5, type: "spring"}
            }}
            exit={{
                opacity: 0,
                transition: {delay: 0.5}
            }}
            layout
            transition={{duration: 0.5}}>
            {searchResult && !loadingSearch ?
                <AddNewCardForm setSearchResult={setSearchResult}
                                setOpenDrawer={setOpenDrawer}
                                searchResult={searchResult}
                                setItems={setItems}
                                items={items}
                                title={title}
                                onChange={onChange}
                                setTitle={setTitle}/>
                : <NewItemPlaceholderCard setSearchResult={setSearchResult}
                                          affix={affix}
                                          fetchResult={fetchResult}
                                          setAffix={setAffix}
                                          loadingSearch={loadingSearch}/>
            }
        </motion.div>
    </>;
};