import './App.css';
import StepsContainer from "./components/steps/StepsContainer";
import SuccessContainer from "./components/steps/SuccessContainer";
import {Component} from "react";
import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./components/error/ErrorPage";
import {RouterProvider} from "react-router";
import ChoosePage from "./components/choose/ChoosePage";
import SuccessChooseContainer from "./components/choose/SuccessChooseContainer";
import HomePage from "./components/home/HomePage";
import LayoutWrapper from "./components/layout/Layout";
import {AnimatePresence} from "framer-motion";
import IntegrationPage from "./components/integration/IntegrationPage";
import IntegrationChoosePreviewPage from "./components/Integration-choose-preview/IntegrationChoosePreviewPage";
import ApiDocsPage from "./components/api-docs/ApiDocsPage";
import ContactPage from "./components/contact/ContactPage";
import CompanyStepsPage from "./components/steps/company/CompanyStepsPage";
import {ConfigProvider} from "antd";
import {CookieConsent} from "react-cookie-consent";
import CookiePopup from "./components/layout/CookiePopup";
import PrivacyPolicyPage from "./components/privacy-policy/PrivacyPolicyPage";
import RulesPage from "./components/privacy-policy/RulesPage";
import ShopPreviewPage from "./components/shop-preview/ShopPreviewPage";
import SuccessDeletePage from "./components/delete/SuccessDeletePage";

const routes = [
    {
        path: "/",
        element: <HomePage/>,
    },
    {
        path: "shop-preview",
        element: <ShopPreviewPage/>
    },
    {
        path: "integration",
        element: <IntegrationPage/>
    },
    {
        path: "create",
        element: <StepsContainer/>,
    },
    {
        path: "create/:giftId",
        element: <CompanyStepsPage/>,
    },
    {
        path: "success",
        element: <SuccessContainer/>,
    },
    {
        path: "choose/:giftId",
        element: <ChoosePage/>
    },
    {
        path: "choose-success",
        element: <SuccessChooseContainer/>
    },
    {
        path: "integration-preview",
        element: <IntegrationChoosePreviewPage/>
    },
    {
        path: 'contact',
        element: <ContactPage/>
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />
    },
    {
        path: "rules",
        element: <RulesPage />
    },
    {
        path: "home",
        element: <HomePage/>
    },
    {
        path: "delete/:giftId",
        element: <SuccessDeletePage/>
    }
];
const router = createBrowserRouter([{
    path: "/",
    element: <LayoutWrapper/>,
    errorElement: <ErrorPage/>,
    children: routes
},
    {
        path: "api-docs",
        element: <ApiDocsPage/>,
        errorElement: <ErrorPage/>,
    }]);

class App extends Component {
    render() {
        return (<AnimatePresence mode="wait">
                <ConfigProvider theme={{
                    token: {
                        fontFamily: "-apple-system, BlinkMacSystemFont, \"Protest Strike\", 'Roboto', 'Oxygen', sans-serif"
                    }
                }}>
                    <RouterProvider router={router}/>
                    <CookiePopup />
                </ConfigProvider>
            </AnimatePresence>
        );
    }
}

export default App;
