import Marquee from "react-marquee-slider";
import React, { useEffect, useState } from 'react';
import { Avatar, Card, Flex, Rate, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {TESTIMONIALS} from "../../config/testimonials";

const Testimonials = ({size = 1000}) => {
    const getWindowSize = () => {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    };

    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    let scale = 0.5;

    if (size && windowSize.innerWidth > 800) {
        scale = 0.65;
    }

    if (size && windowSize.innerWidth > 1100) {
        scale = 0.8;
    }

    if (size && windowSize.innerWidth > 1400) {
        scale = 1;
    }

    return (
        <Flex align={"center"} justify={"center"} style={{width: "100%", height: 600 * scale}}>
            <Marquee key={1} velocity={40} scatterRandomly minScale={0.7} resetAfterTries={100}>
                {TESTIMONIALS.map((item, index) => (
                    <Card key={index} style={{width: scale * 320, fontSize: Math.min(12, scale * 16)}} bodyStyle={{padding: 18 * scale}}
                          hoverable>
                        <Space direction="vertical" wrap size={16 * scale}>
                            <div>
                                <Avatar size={48 * scale} src={item.img} style={{marginRight: 8, marginBottom: 4}}/> <b>{item.name}</b> <br/>
                                {item.text}
                            </div>
                            {scale > 0.7 && <Rate disabled defaultValue={5} style={{fontSize: 20 * scale}}/>}
                        </Space>
                    </Card>
                ))}
            </Marquee>
        </Flex>
    );
};

export default Testimonials;