import React, {useEffect, useMemo, useState} from 'react';
import ButtonCreate from "./ButtonCreate";
import {Anchor, Button, Flex, Image, Typography} from "antd";
import {TypeAnimation} from "react-type-animation";
import {ReactComponent as UserWorking} from "../../assets/userWorking.svg";
import {ReactComponent as GiftHappy} from "../../assets/giftHappy.svg";
import {ReactComponent as Send} from "../../assets/send.svg";
import {motion} from "framer-motion";
import styles from "./HomePage.module.css";
import MiddlePageContainer from "./MiddlePageContainer";
import Lottie from "lottie-react";
import apiAnimation from "../../assets/apiAnimation.json";
import adAnimation from "../../assets/adAnimation.json";
import blobCorner from "../../assets/blobCorner.json";
import {ReactComponent as WavesDivider} from '../../assets/wavesDivider.svg'
import Testimonials from "./Testimonials";
import {useNavigate} from "react-router-dom";
import presentAnimation from "../../assets/Animation - 1705411779434.json";
import InformationCard from "./InformationCard";
import {ArrowDownOutlined} from "@ant-design/icons";
import ProcessDescriptionContainer from "./ProcessDescriptionContainer";
import {useTranslation} from "react-i18next";

const easing = [0.6, -0.05, 0.01, 0.99];
const visible = {opacity: 1, y: 0, transition: {duration: 0.5}};

const HomePage = () => {
    const [textColor, setTextColor] = useState('#FD5252FF');
    const [targetOffset, setTargetOffset] = useState();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation('home');

    const topRef = React.useRef(null);
    useEffect(() => {
        setTargetOffset(topRef.current?.clientHeight);
    }, []);

    const sequence = useMemo(() => [
        t('header.friend'),
        1000,
        '',
        () => setTextColor('#dda15e'),
        t('header.colleague'),
        1000,
        '',
        () => setTextColor('#FD5252FF'),
        t('header.boyfriend'),
        1000,
        '',
        () => setTextColor('#ff8fab'),
        t('header.girlfriend'),
        1000,
        '',
        () => setTextColor('#ffc300'),
        t('header.boss'),
        1000,
        '',
        () => setTextColor('#6a994e'),
        ''
    ], [i18n.language, t]);

    return (
        <motion.div initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    whileInView="onscreen"
                    viewport={{once: true, amount: 0.8}}
                    exit={{opacity: 0, transition: {duration: 1}}}
                    variants={{visible: {transition: {staggerChildren: 0.3}}}}
                    transition={{duration: 1, ease: easing}}>
            <main className={styles.wave}>
                <Lottie className={styles.blob} animationData={blobCorner} loop={true} />

                {/*<img className={styles.blob} src={blob} alt={'Background blob'}/>*/}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#fff" fill-opacity="1"
                          d="M0,288L48,282.7C96,277,192,267,288,272C384,277,480,299,576,293.3C672,288,768,256,864,245.3C960,235,1056,245,1152,240C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
                <Flex justify={"center"} wrap={"wrap"} className={styles.homePageHeroFlex} align={"flex-end"}
                      style={{borderBottom: '15px solid #bc83fc'}}
                      id={"home"}>
                    <Flex vertical justify={"space-evenly"} align={"center"} flex={1} gap={"large"} style={{marginBottom: '150px'}}>
                        <motion.div
                            variants={{
                                hidden: {opacity: 0, y: -20},
                                visible
                            }}
                            style={{
                                fontSize: '4em',
                                color: 'white',
                                padding: "1em",
                                fontWeight: 700,
                                textAlign: "center",
                                textShadow: '1px 1px 2px black',
                                zIndex: 1
                            }}>{t('header.title')}
                            {' '}
                            <span style={{color: textColor}}>
                                <br/>
                                    <TypeAnimation
                                        key={i18n.language}
                                        sequence={sequence}
                                        wrapper="span"
                                        speed={50}
                                        style={{display: 'inline-block'}}
                                        repeat={Infinity}
                                    />

                    </span>

                        </motion.div>
                        <Flex align={"center"} justify={"center"} style={{width: "100%"}}>
                            <ButtonCreate/>
                        </Flex>
                        <Flex align={"center"} justify={"center"} style={{width: "100%", marginTop: 128, zIndex: 1}}>
                            <Anchor
                                affix={false}
                                bounds={0}
                                offsetTop={100}
                                targetOffset={targetOffset} items={[{
                                key: 'home',
                                href: '#home',
                                title: '',
                            }, {
                                key: 'cards',
                                href: '#cards',
                                title: <ArrowDownOutlined style={{color: "white", fontSize: "2em"}}/>,
                            }]}>
                            </Anchor>
                        </Flex>
                    </Flex>
                    <div style={{flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "flex-start", position: 'relative'}}>
                        <motion.div
                                    initial={{
                                        opacity: 0,
                                        y: 150
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0,
                                        transition: {
                                            duration: 1
                                        }
                                    }}
                                    viewport={{ once: true }}>
                        <Image preview={false} src={`${process.env.PUBLIC_URL}/mockup_hand.webp`} style={{flex: 1, minWidth: '600px'}}/>
                        </motion.div>
                    </div>
                </Flex>

                <div style={{position: "relative"}} className={styles.wavesDivider}>
                <div style={{transform: 'rotateX(180deg)', position: 'absolute', transformOrigin: '100% 0'}} className={styles.customShapeDivider}>
                    <WavesDivider />                </div>

                <div className={styles.customShapeDivider} style={{position: 'absolute'}}>
                    <WavesDivider />
                </div>
                </div>

                <Flex justify={"space-evenly"} align={"center"} wrap={"wrap"} gap={"middle"}
                      style={{width: "100%", marginTop: '30dvh', textAlign: "center"}}>
                    <InformationCard title={t('information.title.create')}
                                     cover={<UserWorking/>}
                                     index={0}
                                     description={<span>{t('information.create')}</span>}/>
                    <InformationCard title={t('information.title.send')}
                                     index={1}
                                     cover={<GiftHappy/>}
                                     description={t('information.send')}/>
                    <InformationCard title={t('information.title.notification')}
                                     index={2}
                                     cover={<Send/>}
                                     description={t('information.notification')}/>
                </Flex>
            </main>

            <MiddlePageContainer title={t('middlePage.title.api')}
                                 text={<>
                                     {t('middlePage.description.api1')}
                                     <b>{t('middlePage.description.api2')}</b>
                                     {t('middlePage.description.api3')}
                                     <br/><br/>
                                     <Button size={"large"} onClick={() => navigate("/integration")} type={"primary"}>
                                         {t('middlePage.description.readMore')}
                                     </Button>
                                 </>}
                                 icon={<Lottie animationData={apiAnimation} loop={true}/>}
                                 index={0}
                                 innerTitle={t('middlePage.innerTitle.api')}
            />

            <MiddlePageContainer
                title={t('middlePage.title.ads')}
                text={<>
                    <b>{t('middlePage.description.ads1')}</b>
                    {t('middlePage.description.ads2')}
                    <br/>
                    <b>{t('middlePage.description.ads3')}</b>
                    <br/><br/>
                    <Button size={"large"} onClick={() => navigate("/contact")}
                            type={"primary"}>{t('middlePage.description.contact')}</Button>
                </>}
                icon={<Lottie animationData={adAnimation} autoplay loop={true}/>}
                left
                index={1}
                innerTitle={t('middlePage.innerTitle.ads')}
            />

            <MiddlePageContainer title={t('middlePage.title.corporation')}
                                 icon={<Lottie animationData={presentAnimation} loop={true}/>}
                                 index={2}
                                 innerTitle={t('middlePage.innerTitle.corporation')}
                                 text={
                                     <>
                                         {t('middlePage.description.corporation1')}
                                         <b>{t('middlePage.description.corporation2')}</b>
                                         <br/>
                                         {t('middlePage.description.corporation3')}

                                         <br/><Typography.Title
                                         level={5}>{t('middlePage.description.corporation4')}</Typography.Title>
                                         <Button size={"large"} onClick={() => navigate("/integration-preview")}
                                                 type={"primary"}>{t('middlePage.description.demo')}</Button>
                                     </>
                                 }
            />

            <Testimonials/>
            <ProcessDescriptionContainer/>
        </motion.div>
    );
};

export default HomePage;