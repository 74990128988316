import React, {useRef} from 'react';
import {ReactComponent as Success} from "../../assets/success.svg";
import {ReactComponent as Discard} from "../../assets/discard.svg";
import {Button, Card, Checkbox, Divider, Flex, Input, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import styles from "./AddNewCardForm.module.css"
import {useTranslation} from "react-i18next";

const plainOptions = t => [
    {
    label: t('card.option.sizeLabel'),
    value: 'size',
},
    {
        label: t('card.option.colorLabel'),
        value: 'color',
    }, {
        label: t('card.option.noteLabel'),
        value: 'note',
    }];

const AddNewCardForm = ({searchResult, items, setItems, setSearchResult, setOpenDrawer, title, onChange, setTitle}) => {
    const inputRef = useRef(null);
    const {t} = useTranslation('steps');

    const onTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        setSearchResult({
            ...searchResult,
            titles: [...searchResult.titles, title],
            title: title
        });
        setTitle('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    return (
        <Card
            hoverable
            className={styles.container}
            headStyle={{maxWidth: 400}}
            title={searchResult.title}
            actions={[
                <Success key="success" className={styles.icon} onClick={() => {
                    if (searchResult.title && searchResult.images && searchResult.images.length > 0) {
                        setItems([...items, searchResult]);
                        setSearchResult(null);
                    }
                }}/>,
                <Discard key="discard" className={styles.icon}
                         onClick={() => setSearchResult(null)}/>,
            ]}
            cover={searchResult.images?.length &&
                <img alt="Add new item" className={styles.image}
                     onClick={() => setOpenDrawer(true)}
                     src={searchResult?.images[searchResult?.imgIndex]}/>}
        >
            <Flex vertical align={"center"} justify={"center"}>
                <Button type={"dashed"} size={"large"} onClick={() => setOpenDrawer(true)}>{t('button.image')}</Button>
                <Divider style={{marginBottom: 0}}>{t('card.titleDivider')}</Divider>
                <Select
                    size={"large"}
                    className={styles.select}
                    defaultValue={searchResult.title}
                    value={searchResult.title}
                    onChange={(value) => setSearchResult({...searchResult, title: value})}
                    options={searchResult.titles.map(value => ({value, label: value}))}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{margin: '8px 0',}}
                            />
                            <Space style={{padding: '0 8px 4px',}}>
                                <Input
                                    placeholder={t('card.titlePlaceholder')}
                                    ref={inputRef}
                                    value={title}
                                    onChange={onTitleChange}
                                    onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                    {t('button.add')}
                                </Button>
                            </Space>
                        </>
                    )}
                />
                <Divider>{t('card.option.divider')}</Divider>
                <Checkbox.Group options={plainOptions(t)} onChange={onChange}/>
                <Divider/>
                <Meta description={searchResult.url}/>
            </Flex>
        </Card>
    );
};

export default AddNewCardForm;