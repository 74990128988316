import React, { useRef, useState } from 'react';
import {Button, Col, Divider, Form, Image, Row, Tour, Typography} from "antd";
import noImage from "../../assets/noImage.png";
import styles from './IntegrationChoosePreviewPage.module.css';
import Carousel from "./Carousel";
import ShippingDetails from "../choose/ShippingDetails";
import {Trans, useTranslation} from "react-i18next";

const IntegrationChoosePreviewPage = () => {
    const [form] = Form.useForm();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [open, setOpen] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(1)
    const {t} = useTranslation('integration');

    const steps = [
        {
            title: t('tour.steps.logo.title'),
            description: t('tour.steps.logo.description'),
            target: () => ref1.current,
            nextButtonProps: {children: t('button.next')}
        },
        {
            title: t('tour.steps.item.title'),
            description: t('tour.steps.item.description'),
            target: () => ref2.current,
            nextButtonProps: {children: t('button.next')},
            prevButtonProps: {children: t('button.previous')},
        }
        ,
        {
            title: t('tour.steps.shipping.title'),
            description: <Trans t={t} i18nKey="tour.steps.shipping.description" components={{ 1: <br /> }} />,
            target: () => ref3.current,
            prevButtonProps: {children: t('button.previous')},
            nextButtonProps: {children: t('button.end')},
        }
    ]

    return (
        <main>
            <Divider style={{borderBlockStartColor: "#bc83fc"}}>
                <Typography.Title level={3}>
                    <b style={{color: "gray", fontSize: 24}}>{t('divider.preview')}</b>
                </Typography.Title></Divider>
            <Row justify={"center"}>
                <Col className={styles.minHeight} ref={ref1}><Image preview={false} src={noImage} width={100}/></Col>
            </Row>
            <Row justify={"center"}>
                {t('divider.logo')}
            </Row>
            <Divider ellipsis={true} style={{borderBlockStartColor: "#bc83fc"}}>
                <b style={{color: "gray", fontSize: 24}}>{t('divider.item')}</b>
            </Divider>
            <Carousel reference={ref2} selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}/>
            <Divider ellipsis={true} style={{borderBlockStartColor: "#bc83fc"}}>
                <b style={{color: "gray", fontSize: 24}}>{t('divider.shipping')}</b>
            </Divider>

            <Row justify={"center"}>
                <ShippingDetails reference={ref3} disabled={true} gift={{items: []}} form={form} onSubmit={() => {
                }}/>
            </Row>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps} style={{width: 300}}  />
        </main>
    );
};

export default IntegrationChoosePreviewPage;