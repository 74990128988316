import React, {useRef} from 'react';
import {Button, Divider, Empty, Flex, Form, Input, Select, Typography} from "antd";
import {motion} from "framer-motion"
import WelcomeModal from "../choose/WelcomeModal";
import {EyeFilled, PlusOutlined} from "@ant-design/icons";
import PrefixSelector from "../form/PrefixSelector";
import {OCCASIONS} from "../../config/occasion";
import logoPlaceholder from "../../assets/logoPlaceholder.jpg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SubmitButton = ({form}) => {
    const [submittable, setSubmittable] = React.useState(false);
    const {t} = useTranslation('steps');

    // Watch all values
    const values = Form.useWatch([], form);
    React.useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                () => {
                    setSubmittable(true);
                },
                () => {
                    setSubmittable(false);
                },
            );
    }, [values]);
    return (
        <Button type="primary" htmlType="submit" disabled={!submittable}>
            {t("button.next")}
        </Button>
    );
};
const InitStepContainer = ({goNext, stepForm, company}) => {
    const {t} = useTranslation('steps');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [occasions, setOccasions] = React.useState(OCCASIONS(t));
    const [newOccasion, setNewOccasion] = React.useState(null);
    const inputRef = useRef(null);

    const toggleModal = () => {
        setIsModalOpen((p) => !p);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (!newOccasion || (newOccasion.length > 50 || newOccasion.length < 5)) {
            return;
        }
        stepForm.setFieldValue('title', newOccasion);
        setOccasions(occasions => [...occasions, {label: newOccasion, value: newOccasion}]);
        setNewOccasion('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    return (
        <>
            <motion.div
                initial={{scaleX: 0}}

                animate={{
                    scaleX: 1,
                    transition: {delay: 0.5, type: "tween"}
                }}
                exit={{
                    opacity: 0,
                    transition: {delay: 0.5}
                }}

                layout
                transition={{duration: 0.5}}>

                <Form size={"large"} style={{marginTop: 20}} form={stepForm} layout="vertical" autoComplete="off"
                      onFinish={goNext}>
                    <Form.Item
                        name="title"
                        label={t("init.label.title")}
                        rules={[
                            {
                                required: true,
                                message: t("init.validation.title"),
                            },
                            {
                                message: t("init.validation.titleLength"),
                                min: 5,
                                max: 50
                            }
                        ]}
                    >
                        <Select
                            listHeight={512}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />
                                    <Flex>
                                        <Input
                                            status={(newOccasion?.length > 50 || newOccasion?.length < 5) ? 'error' : null}
                                            count={{
                                                show: true,
                                                max: 50
                                            }}
                                            style={{
                                                width: '100%'
                                            }}
                                            placeholder={t("init.label.item")}
                                            ref={inputRef}
                                            value={newOccasion}
                                            onChange={event => setNewOccasion(event.target.value)}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                            {t("button.add")}
                                        </Button>
                                    </Flex>
                                </>
                            )}
                            options={occasions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={<Typography.Text>{t("init.label.description")}<Typography.Text
                            type={"secondary"}>{t("init.label.optional")}</Typography.Text></Typography.Text>}
                        help={<p>{t("init.help.description")}</p>}
                        rules={[
                            {
                                required: false,
                                max: 600,
                                message: t("init.validation.description")
                            },
                        ]}
                    >
                        <Input.TextArea allowClear showCount rows={10}/>
                    </Form.Item>
                    <Divider><b style={{color: "gray", fontSize: 24}}>{t("init.label.contact")}</b></Divider>
                    <Form.Item
                        type={"email"}
                        name="email"
                        label={t("init.label.email")}
                        help={t("init.help.email")}
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                min: 5,
                                max: 50,
                                message: t("init.validation.email")
                            },
                        ]}
                    ><Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        help={t("init.help.phone")}
                        label={<Typography.Text>{t("init.label.phone")}<Typography.Text
                            type={"secondary"}>{t("init.label.optional")}</Typography.Text></Typography.Text>}
                        rules={[
                            {
                                message: t("init.validation.phone"),
                                min: 9
                            },
                        ]}
                    >
                        <Input
                            addonBefore={<PrefixSelector/>}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type={"dashed"} block onClick={toggleModal}
                                style={{margin: "24px 0"}}>{t("init.label.preview")}<EyeFilled/></Button>
                        <Flex align={"center"} justify={"center"} gap={"small"}>
                            <Button htmlType="reset">{t("button.reset")}</Button>
                            <SubmitButton form={stepForm}/>
                        </Flex>
                    </Form.Item>
                </Form>
                {/*<Empty*/}
                {/*    style={{border: "2px dashed #251FB6", borderRadius: 12, padding: 8}}*/}
                {/*    image={logoPlaceholder}*/}
                {/*    description={<Link to="/contact" target="_blank"*/}
                {/*                       rel="noopener noreferrer">{t("init.label.company")}</Link>}*/}
                {/*/>*/}
            </motion.div>
            {isModalOpen && <WelcomeModal toggleModal={toggleModal} isModalOpen={isModalOpen}
                                          title={stepForm.getFieldValue("title")}
                                          description={stepForm.getFieldValue("description")}
                                          company={company}
            />}
        </>
    );
};

export default InitStepContainer;