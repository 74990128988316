import React from 'react';
import {InboxOutlined} from '@ant-design/icons';
import {message, Upload} from 'antd';
import HttpService from "../../service/HttpService";
import {S3_URL} from "../../config/URL";
import {useTranslation} from "react-i18next";

const {Dragger} = Upload;
const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/gif','image/bmp'];

const UploadImage = ({addNewImage, removeNewImage}) => {
    const {t} = useTranslation('steps');

    const props = {
        name: 'file',
        multiple: true,
        customRequest: ({file, onError, onSuccess, onProgress}) => {
            const fileType = file.type;
            const fileName = file.name;

            HttpService.getPresignedUrl(fileName, fileType)
                .then(({data}) => {
                    const signedRequest = data.uploadURL;
                    const options = {
                        onUploadProgress: (event) => {
                            const {loaded, total} = event;
                            onProgress(
                                {
                                    percent: Math.round((loaded / total) * 100),
                                },
                                file
                            );
                        },
                        headers: {
                            'Content-Type': fileType,
                        },
                    };

                    return HttpService.uploadFile(signedRequest, file, options)
                        .then(result => {
                            onSuccess(result, file);
                        })

                }).catch(error => {
                onError(error);
                console.error(JSON.stringify(error));
            })
        },
        beforeUpload: (file) => {
            const isImage = allowedExtension.includes(file.type);
            const isTooBig = file.size / (1024 * 1024) > 5;

            if (!isImage) {
                message.error(`${file.name} is not a image file`);
            }
            if (isTooBig) {
                message.error(`${file.name} is bigger than 5 MB`);
            }
            return (isImage && !isTooBig) || Upload.LIST_IGNORE;
        },
        onChange({file}) {

            const {status} = file;
            if (status === 'done') {
                message.success(`${file.name} ${t('card.uploadSuccess')}`);
                addNewImage(S3_URL + file.name)
            } else if (status === 'error') {
                message.error(`${file.name} ${t('card.uploadFailure')}`);
            } else if (status === 'removed') {
                removeNewImage(S3_URL + file.name)
            }
        },
        onDrop(e) {
            message.success(t('card.uploadSuccessDrop'));
        },
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        return (file.url || file.preview);
    };

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined/>
            </p>
            <p className="ant-upload-text">{t('card.uploadImageText')}</p>
            <p className="ant-upload-hint">
                {t('card.uploadImageHint')}
            </p>
        </Dragger>
    );
};

export default UploadImage;