import React from 'react';
import {Button, Flex, Form, Input, Popover, theme} from "antd";
import ChooseFormOptions from "./ChooseFormOptions";
import PrefixSelector from "../form/PrefixSelector";
import {useTranslation} from "react-i18next";


const SubmitButton = ({form, hasOptionsError, expandedIndex, reference}) => {
    const [submittable, setSubmittable] = React.useState(false);
    const {t} = useTranslation('choose')

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                () => {
                    setSubmittable(expandedIndex !== undefined && expandedIndex !== null && !hasOptionsError);
                },
                (val) => {
                    setSubmittable(false);
                },
            );
    }, [expandedIndex, form, hasOptionsError, values]);

    return (<Popover content={submittable ? '' :
            <div>{t('error.form')}{hasOptionsError &&
                <b>{t('error.formNotFilled')}</b>}
            </div>} title={submittable ? t('popover.fine') : t('popover.error')}>

            <Button type="primary" htmlType="submit" disabled={!submittable} ref={reference}>
                {t('button.submit')}
            </Button>
        </Popover>
    );
};

const ShippingDetails = ({
                             form,
                             gift,
                             onSubmit,
                             setOptions,
                             disabled,
                             options,
                             expandedIndex,
                             modalOpen,
                             setModalOpen,
                             reference
                         }) => {
    const {token} = theme.useToken();
    const contentStyle = {
        boxShadow: '0px 11px 35px 4px rgba(0, 0, 0, 0.3)',
        backgroundColor: "#FFFFFF",
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        margin: "16px 16px",
        padding: 24,
        width: 'min(80%, 400px)'
    };
    const {t} = useTranslation('choose')

    return (
        <Form disabled={disabled} style={contentStyle} size={"large"} form={form} layout="vertical" autoComplete="off"
              initialValues={{items: gift.items}}
              onFinish={onSubmit} onReset={() => {
            setOptions(null)
        }}>
            <Form.Item
                name="fullName"
                label={t('label.fullName')}
                rules={[
                    {
                        required: true,
                        max: 100,
                        message: t('validation.name')
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="street"
                label={t('label.street')}
                rules={[
                    {
                        required: true,
                        max: 100,
                        message: t('validation.street')
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="city"
                label={t('label.city')}
                rules={[
                    {
                        required: true,
                        max: 100,
                        message: t('validation.city')
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="zipCode"
                label={t('label.zipcode')}
                rules={[
                    {
                        required: true,
                        max: 100,
                        message: t('validation.zipcode')
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="email"
                label={t('label.email')}
                rules={[
                    {
                        type: 'email',
                        required: true,
                        max: 100,
                        message: t('validation.email')
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="phone"
                label={t('label.phone')}
                rules={[
                    {
                        required: true,
                        message: t('validation.phone'),
                    },
                ]}
            >
                <Input
                    addonBefore={<PrefixSelector/>}
                    style={{
                        width: '100%',
                    }}
                />
            </Form.Item>
            <Form.Item>
                <Flex align={"center"} justify={"center"} gap={"small"}>
                    <SubmitButton
                        reference={reference}
                        hasOptionsError={!options && expandedIndex && gift.items[expandedIndex]?.options?.length > 0}
                        form={form} expandedIndex={expandedIndex}/>
                    <Button htmlType="reset">{t('button.reset')}</Button>
                </Flex>
            </Form.Item>

            <ChooseFormOptions options={gift?.items[expandedIndex]?.options || []} open={modalOpen}
                               onCancel={() => setModalOpen(false)}
                               setValues={val => {
                                   setModalOpen(false);
                                   setOptions(val)
                               }
                               }/>
        </Form>
    );
};

export default ShippingDetails;