import React, {useEffect, useState} from 'react';
import {Badge, Button, Flex, Form, message, Spin, Steps} from 'antd';
import CardContainer from "../card/CardContainer";
import InitStepContainer from "./InitStepContainer";
import FinishStepContainer from "./FinishStepContainer";
import {useNavigate} from "react-router-dom";
import HttpService from "../../service/HttpService";
import {useTranslation} from "react-i18next";
import styles from './StepsContainer.module.css';
import {motion} from "framer-motion";

const StepsContainer = ({companyItems = [], companyIntegrationEnabled = false, company}) => {
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const navigate = useNavigate();
    const {t} = useTranslation('steps');
    const [form] = Form.useForm();
    const [formItems, setFormItems] = useState(form.getFieldValue("items") || companyItems);

    useEffect(() => {
        setButtonDisabled(current === 1 && !formItems.length)
    }, [current, formItems]);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const addItem = (values) => {
        setLoading(true);
        const title = form.getFieldValue("title");
        const email = form.getFieldValue("email");
        const phone = form.getFieldValue("phone");
        const description = form.getFieldValue("description");
        const items = formItems
            .map(item => ({
                image: item.images[item.imgIndex],
                title: item.title,
                url: item.url,
                options: item.options
            }));
        const backgroundType = form.getFieldValue("configName");

        return HttpService.createGift({
            title,
            description,
            email,
            phone,
            items,
            backgroundType,
            companyName: company?.name,
            companyImg: company?.image,
            companyId: company?.id
        })
            .then(({data}) => {
                message.success('Processing complete!');
                navigate(`/success?giftId=${data?.id}&giftUrl=${data?.url}`)
            })
            .catch(error => {
                console.error(error);
                message.error('Processing failed!');
            })
            .finally(() => setLoading(false));
    }

    const steps = [
        {
            title: t("title.first"),
            content: <InitStepContainer goNext={next} stepForm={form} company={company}/>,
        },
        {
            title: <Badge count={formItems.length}>{t("title.second")} 🎁</Badge>,
            content: <CardContainer stepForm={form} onChange={(items) => {
                form.setFieldValue("items", items);
                setButtonDisabled(items.length === 0)
            }}
                                    items={formItems}
                                    setItems={setFormItems}
                                    companyIntegrationEnabled={companyIntegrationEnabled}
            />,
        },
        {
            title: t("title.third"),
            content: <FinishStepContainer stepForm={form}/>,
        },
    ];
    const items = steps.map((item) => ({key: item.title, title: item.title}));

    return (
        <motion.div
            initial={{
                opacity: 0,
                y: 150
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                    duration: 1
                }
            }}
            viewport={{ once: true }}>
        <Flex vertical align={"center"}>
            <Spin size={'large'} spinning={loading} fullscreen/>
            <Steps current={current} items={items} style={{padding: 24, maxWidth: 960}}/>
            <Flex vertical align={"center"} justify={"center"} className={styles.container}>
                {/*<img style={{filter: "blur(48px)", height: "100%", width: "100%", position: "absolute", left: 0, top: 0, overflow: "hidden", transform: "scale(1.2)", zIndex: -1}} src={blobScene} />*/}
                {steps[current].content}
                <Flex style={{marginBottom: 24}}>
                    {current > 0 && current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()} disabled={buttonDisabled}>
                            {t("button.next")}
                        </Button>
                    )}
                    {current === steps.length - 1 && (

                        <Button type="primary" htmlType="submit" onClick={addItem}>
                            {t("button.done")}
                        </Button>
                    )}
                    {current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            {t("button.previous")}
                        </Button>
                    )}
                </Flex>

            </Flex>
        </Flex>
        </motion.div>
    );
};

export default StepsContainer;