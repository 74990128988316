export const TESTIMONIALS = [
    {
        name: 'Emily',
        text: 'GiftPick made finding the perfect gift for my sister\'s birthday a breeze!',
        img: 'https://assets-giftly.s3.eu-north-1.amazonaws.com/person1.jpg'
    },
    {
        name: 'Sarah',
        text: 'GiftPick\'s shop integration feature has significantly boosted sales for my online store.',
        img: 'https://assets-giftly.s3.eu-north-1.amazonaws.com/person4.jpg'
    },
    {
        name: 'Ryan',
        text: 'The seamless onboarding process with GiftPick\'s shareable links has made welcoming new team members a pleasure.',
        img: 'https://assets-giftly.s3.eu-north-1.amazonaws.com/person2.jpg'
    },
    {
        name: 'David',
        text: 'The notification feature from GiftPick ensures I never miss out when my friends choose their gifts from my curated list.',
        img: 'https://assets-giftly.s3.eu-north-1.amazonaws.com/person3.jpg'
    }
]