import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadAll } from "@tsparticles/all";
import { BACKGROUNDS } from "../../config/constants";
import styles from './Background.module.css';

const Background = ({children, configName, overrideCss}) => {
    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadAll(engine);
        }).then(() => {
            setInit(true);
        });
    }, [])

    const options = useMemo(
        () => (BACKGROUNDS[configName]?.config || BACKGROUNDS.CONFETTI),
        [configName],
    );

    if (init && configName) {
        return (
            <Particles
                className={overrideCss && styles.tsparticles}
                id="tsparticles"
                options={options}
            >
                {children}
            </Particles>
        );
    }

    return <>{children}</>;
};

export default Background;