import React, {useRef, useState} from 'react';
import {Avatar, Button, Card, Col, Flex, FloatButton, List, Popover, Row, Tour} from "antd";
import Meta from "antd/es/card/Meta";
import {GiftFilled, GiftOutlined} from "@ant-design/icons";
import {Trans, useTranslation} from "react-i18next";

const ShopPreviewPage = () => {
    const [items, setItems] = useState([]);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [open, setOpen] = useState(true);
    const {t} = useTranslation('integration');

    const addOrRemoveItem = index => () =>
        setItems(items => items.includes(index)
            ? items.filter(el => el !== index)
            : [...items, index])

    const steps = [
        {
            title: <Trans t={t} i18nKey="shopTour.steps.first.title" />,
            description: <Trans t={t} i18nKey="shopTour.steps.first.description" />,
            target: () => ref1.current,
            nextButtonProps: {children: t('button.next')},
            prevButtonProps: {children: t('button.previous')},
        },
        {
            title: <Trans t={t} i18nKey="shopTour.steps.second.title" />,
            description: <Trans t={t} i18nKey="shopTour.steps.second.description" components={{ 1: <br /> }} />,
            target: () => ref2.current,
            nextButtonProps: {children: t('button.next')},
            prevButtonProps: {children: t('button.previous')},
        }
    ]

    const content = (
        <div>
            <List
                itemLayout="horizontal"
                dataSource={items}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={`https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png`} />}
                            title={t("shopTour.item.title")}
                            description={t("shopTour.item.description")}
                        />
                    </List.Item>
                )} />
            <Button type={'primary'}>{t("shopTour.button")}</Button>
        </div>
    );

    return (
        <>
            <Flex justify={"center"} align={"center"}>
                <Row gutter={[16, 16]} style={{maxWidth: 1200, marginTop: 32, marginBottom: 32}} justify={"center"} align={"top"}>

                    {new Array(10).fill(0).map((_, index) =>
                        <Col
                            key={index}
                            xs={{flex: '100%'}}
                            sm={{flex: '70%'}}
                            md={{flex: '50%'}}
                            lg={{flex: '30%'}}
                            xl={{flex: '20%'}}
                        ><Card
                            key={index}
                            hoverable
                            style={{width: 240}}
                            cover={<img alt="example"
                                        src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"/>}
                            actions={[<GiftOutlined onClick={addOrRemoveItem(index)}
                                                    ref={index === 0 ? ref1 : null}
                                                    style={{color: items.includes(index) ? 'red' : '#bc83fc', fontSize: 24}}
                            />]}
                        >
                            <Meta title={t("shopTour.item.title")} description="www.website.com"/>
                        </Card></Col>)}
                </Row>

            </Flex>
            <Popover content={content} title={t("shopTour.listTitle")} trigger="click">
                <FloatButton type="primary"
                             ref={ref2}
                         icon={<GiftFilled/>} shape={'square'} style={{insetInlineEnd: 32}}
                         badge={{count: items.length}}
            tooltip={t("shopTour.tooltip")}
            />
            </Popover>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        </>
    );
};

export default ShopPreviewPage;