import React, {useEffect, useRef, useState} from 'react';
import {motion} from "framer-motion";
import {Anchor, Col, Row, Typography} from "antd";
import styles from './ProcessDescriptionContainer.module.css';
import classNames from "classnames";
import {HomeFilled, PictureFilled, SettingFilled} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

const TEXTS = t => [
    {
        id: 1,
        title: t('processDescription.steps.title1'),
        desc: t('home:processDescription.steps.desc1'),
        icon: <HomeFilled/>
    },
    {
        id: 2,
        title: t('home:processDescription.steps.title2'),
        desc: t('home:processDescription.steps.desc2'),
        icon: <SettingFilled/>
    },
    {
        id: 3,
        title: t('home:processDescription.steps.title3'),
        desc: t('home:processDescription.steps.desc3'),
        icon: <PictureFilled/>
    }
]

const ProcessDescriptionContainer = () => {
    const [currentVideo, setCurrentVideo] = useState("#1");
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const {t} = useTranslation('home');

    const getWindowSize = () => {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    };
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(getWindowSize());
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (currentVideo === "#1" && ref1) {
            ref1?.current?.play();
        } else if (currentVideo === "#2" && ref2) {
            ref2?.current?.play();
        } else if (currentVideo === "#3" && ref3) {
            ref3?.current?.play();
        }
    }, [currentVideo])

    return (
        <motion.section
            className={styles.container}
            initial={{
                opacity: 0,
                y: -50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                    duration: 1 // Animation duration
                }
            }}
            viewport={{once: true}}>
            <Typography.Title level={1}>{t('processDescription.title')}</Typography.Title>
            <Typography.Title level={3} style={{color: "gray"}}>{t('processDescription.innerTitle')}</Typography.Title>

            <Row gutter={[16, 16]} justify={"center"} className={styles.content}>
                <Col xs={24} md={24} lg={12}>
                    <Row gutter={[16, 16]} justify={"center"}>
                        <Col xs={24} md={24} lg={6}>
                            {windowSize.innerWidth > 940 ? <Anchor affix={false}
                                                                   className={styles.anchorSticky}
                                                                   offsetTop={500}
                                                                   onChange={currentActiveLink => {
                                                                       setCurrentVideo(currentActiveLink)
                                                                   }}
                                                                   items={[
                                                                       {key: "1", href: "#1", title: "1"},
                                                                       {key: "2", href: "#2", title: "2"},
                                                                       {key: "3", href: "#3", title: "3"}
                                                                   ]}/> :
                                <Row gutter={[16, 16]} justify={"center"} style={{marginTop: 10}}>
                                    <button className={styles.navigationButton} onClick={() => setCurrentVideo("#1")}>
                                        <svg data-active={currentVideo === "#1"} className={styles.buttonSvg}
                                             xmlns="http://www.w3.org/2000/svg" width="54" height="54"
                                             viewBox="0 0 54 54" fill="none">
                                            <path
                                                d="M52 40V14C52 7.37258 46.6274 2 40 2H24.5H14C7.37258 2 2 7.37258 2 14V40C2 46.6274 7.37258 52 14 52H40C46.6274 52 52 46.6274 52 40Z"
                                                stroke="#251FB6" stroke-width="3" stroke-linecap="round"></path>
                                        </svg>
                                        <span className={styles.navigationButtonSpan} data-count={1}></span>
                                    </button>
                                    <button className={styles.navigationButton} onClick={() => setCurrentVideo("#2")}>
                                        <svg data-active={currentVideo === "#2"} className={styles.buttonSvg}
                                             xmlns="http://www.w3.org/2000/svg" width="54" height="54"
                                             viewBox="0 0 54 54" fill="none">
                                            <path
                                                d="M52 40V14C52 7.37258 46.6274 2 40 2H24.5H14C7.37258 2 2 7.37258 2 14V40C2 46.6274 7.37258 52 14 52H40C46.6274 52 52 46.6274 52 40Z"
                                                stroke="#251FB6" stroke-width="3" stroke-linecap="round"></path>
                                        </svg>
                                        <span className={styles.navigationButtonSpan} data-count={2}></span>
                                    </button>
                                    <button className={styles.navigationButton} onClick={() => setCurrentVideo("#3")}>
                                        <svg data-active={currentVideo === "#3"} className={styles.buttonSvg}
                                             xmlns="http://www.w3.org/2000/svg" width="54" height="54"
                                             viewBox="0 0 54 54" fill="none">
                                            <path
                                                d="M52 40V14C52 7.37258 46.6274 2 40 2H24.5H14C7.37258 2 2 7.37258 2 14V40C2 46.6274 7.37258 52 14 52H40C46.6274 52 52 46.6274 52 40Z"
                                                stroke="#251FB6" stroke-width="3" stroke-linecap="round"></path>
                                        </svg>
                                        <span className={styles.navigationButtonSpan} data-count={3}></span>
                                    </button>
                                </Row>}
                        </Col>
                        <Col xs={24} md={24} lg={18}>
                            {TEXTS(t).map(item =>
                                <div key={item.id} id={item.id} className={styles.card}
                                     style={windowSize.innerWidth < 940 ? {display: currentVideo !== `#${item.id}` ? "none" : "block"} : {}}>
                                    <span className={styles.cardIcon}>{item.icon}</span>
                                    <Typography.Title className={styles.cardTitle}
                                                      level={3}>{item.title}</Typography.Title>
                                    <Typography.Paragraph className={styles.cardDesc}>{item.desc}</Typography.Paragraph>
                                </div>
                            )}
                        </Col>
                    </Row>

                </Col>
                <Col xs={24} md={24} lg={12} className={styles.videoWrapper}>
                    <video ref={ref1} className={classNames(styles.video, {
                        [styles.activeVideo]: currentVideo === "#1",
                        [styles.inactiveVideo]: currentVideo !== "#1"
                    })} autoplay="true"
                           muted="muted" playsInline="playsinline" preload="metadata">
                        <source type="video/mp4"
                                src="https://assets-giftly.s3.eu-north-1.amazonaws.com/1.mp4"/>
                    </video>
                    <video ref={ref2} className={classNames(styles.video, {
                        [styles.activeVideo]: currentVideo === "#2",
                        [styles.inactiveVideo]: currentVideo !== "#2"
                    })} autoplay="true"
                           muted="muted" playsInline="playsinline" preload="metadata">
                        <source type="video/mp4"
                                src="https://assets-giftly.s3.eu-north-1.amazonaws.com/4.mp4"/>
                    </video>
                    <video ref={ref3} className={classNames(styles.video, {
                        [styles.activeVideo]: currentVideo === "#3",
                        [styles.inactiveVideo]: currentVideo !== "#3"
                    })} autoPlay="true"
                           muted="muted" playsInline="playsinline" preload="metadata">
                        <source type="video/mp4"
                                src="https://assets-giftly.s3.eu-north-1.amazonaws.com/5.mp4"/>
                    </video>
                </Col>
            </Row>
        </motion.section>
    );
};

export default ProcessDescriptionContainer;