import React from 'react';
import {Button, Col, Divider, Flex, Image, Row, Tooltip, Typography} from "antd";
import screenshot from '../../assets/screenshot.png'
import {motion} from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

const easing = [0.6, -0.05, 0.01, 0.99];

const IntegrationPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation('integration');

    return (
        <motion.section initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0, transition: {duration: 1}}}
                        variants={{visible: {transition: {staggerChildren: 0.3}}}}
                        transition={{duration: 1, ease: easing}} style={{padding: 25}}>
            <Row gutter={[16, 16]} wrap={true}>
                <Col flex="1 1 400px" md={{span: 24}} lg={{span: 12}}>
                    <Typography.Title level={3}>{t('about.whatIs.title')}</Typography.Title>
                    <Typography.Text>{t('about.whatIs.text')}</Typography.Text>
                    <Typography.Title level={3}>{t('about.benefits.title')}</Typography.Title>
                    <Typography.Text>{t('about.benefits.text')}</Typography.Text>
                    <Typography.Title level={3}>{t('about.hard.title')}</Typography.Title>
                    <Typography.Text>{t('about.hard.text')}</Typography.Text>
                    <Typography.Title level={3}>{t('about.cost.title')}</Typography.Title>
                    <Typography.Text>{t('about.cost.text')}</Typography.Text>

                </Col>
                <Col flex="1 1 500px" md={{span: 24}} lg={{span: 12}} style={{
                    boxShadow: '0px 11px 35px 2px rgba(0, 0, 0, 0.14)',
                    backgroundColor: "#FFFFFF", minHeight: "50dvh"
                }}>
                    <Flex vertical align={"center"}>
                        <div style={{position: "relative"}}>
                            <Image preview={false} style={{minWidth: 300, maxWidth: '900px', position: 'relative'}}
                                   src={screenshot}
                                   alt={"Example integration"}/>
                            <Tooltip open={true} placement={'left'}
                                     title={<Trans t={t} i18nKey="about.tooltip" components={{ 1: <br /> }} />}>
                                <Button type={"primary"} style={{
                                    position: "absolute",
                                    bottom: -20,
                                    right: 0,
                                    width: "39%"
                                }}>{t('button.create')}</Button>
                            </Tooltip>
                        </div>
                    </Flex>
                </Col>
            </Row>
            <Divider style={{marginTop: 48}}/>
            <Row gutter={[24, 48]} align="middle" justify={"center"} style={{marginTop: 48}}>
                <Col xs={{ span: 24 }} md={{span: 8}}>
                    <Button block onClick={() => navigate("/api-docs")}>{t('button.documentation')}</Button>
                </Col>
                <Col  xs={{ span: 24 }} md={{span: 8}}>
                    <Button block onClick={() => navigate("/integration-preview")} type={"primary"}>{t('button.demo')}</Button>
                </Col>
                <Col  xs={{ span: 24  }} md={{span: 8}}>
                    <Button block onClick={() => navigate("/shop-preview")} type={"primary"}>{t('button.demoCreate')}</Button>
                </Col>
            </Row>
        </motion.section>
    );
};

export default IntegrationPage;