import React from 'react';
import {TypeAnimation} from "react-type-animation";
import {Drawer} from "antd";

const InspirationsContainer = ({inspiration, setInspirations}) => {
    return (
        <Drawer onClose={() => setInspirations(null)}
                size={"large"}
                title={"Inspirations"}
                open={inspiration}>
            {inspiration && <TypeAnimation sequence={[inspiration]}
                                           speed={100}
                                           style={{ whiteSpace: 'pre-line', marginBottom: 16, display: 'block' }}/>
            }
        </Drawer>
    );
};

export default InspirationsContainer;